// src/app/features/auth/components/change-password-form.tsx
import ChangePasswordSchema from "../../../@core/schema/chagne-password-schema";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import ChangePasswordFormFieldView from "./change-password-form-field-view";
import ChangePasswordSubmitView from "../change-password-submit-view";
import useChangePassword from "../hook/use-change-password";
import {useNavigate} from "react-router-dom";

const ChangePasswordForm = () => {
  const navigate = useNavigate();
  const {changePassword, isLoading, error} = useChangePassword();

  const form = useForm<z.infer<typeof ChangePasswordSchema>>({
    resolver: zodResolver(ChangePasswordSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof ChangePasswordSchema>) => {
    try {
      await changePassword(values);
      navigate("/profile"); // Redirect to profile page after successful change
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <ChangePasswordFormFieldView
          name="oldPassword"
          label="كلمة المرور القديمة"
          placeholder="أدخل كلمة المرور القديمة"
          inputType="password"
          formControl={form.control}
        />
        <ChangePasswordFormFieldView
          name="newPassword"
          label="كلمة المرور الجديدة"
          placeholder="أدخل كلمة المرور الجديدة"
          inputType="password"
          formControl={form.control}
        />
        <ChangePasswordFormFieldView
          name="confirmPassword"
          label="تأكيد كلمة المرور الجديدة"
          placeholder="أدخل تأكيد كلمة المرور الجديدة"
          inputType="password"
          formControl={form.control}
        />
        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
        <ChangePasswordSubmitView isLoading={isLoading} />
      </form>
    </Form>
  );
};

export default ChangePasswordForm;
