import React, { useEffect,  useState } from "react";
import OpportunityCard from "@/app/global-components/opportunity-card";
import useOffer from "@/app/features/tender/components/hook/useTender";


const OffersPage: React.FC = () => {
  const [opportunities, setOpportunities] = useState<any[]>([]);
  const { getOffers } = useOffer();
  /*
  const opportunities = [
    {
      id: 1,
      title: "توريد أجهزة الأشعة السينية",
      category: "أجهزة طبية",
      location: "الرياض، المملكة العربية السعودية",
      description:
        "مناقصة لتوريد وتركيب أجهزة الأشعة السينية لمستشفيات المنطقة.",
      status: "مفتوح",
      city: "الرياض",
      region: "المملكة العربية السعودية",
      categories: ["أجهزة طبية"],
      endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
      remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 10 days from now
    },
    {
      id: 2,
      title: "صيانة أجهزة التخدير",
      category: "خدمات صيانة",
      location: "جدة، المملكة العربية السعودية",
      description:
        "مطلوب شركة متخصصة لتقديم خدمات الصيانة الدورية لأجهزة التخدير في المستشفيات.",
      status: "مفتوح",
      city: "الرياض",
      region: "المملكة العربية السعودية",
      categories: ["أجهزة طبية"],
      endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
      remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, 
    },
    {
      id: 3,
      title: "توريد معدات جراحة العظام",
      category: "أجهزة جراحية",
      location: "دبي، الإمارات العربية المتحدة",
      description:
        "مناقصة لتوريد معدات جراحة العظام، بما في ذلك الأطراف الاصطناعية والأدوات الجراحية.",
      status: "مفتوح",
      city: "الرياض",
      region: "المملكة العربية السعودية",
      categories: ["أجهزة طبية"],
      endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
      remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, 
    },
    {
      id: 4,
      title: "توريد أجهزة التصوير بالرنين المغناطيسي",
      category: "أجهزة تشخيصية",
      location: "القاهرة، مصر",
      description:
        "مناقصة لتوريد وتركيب أجهزة التصوير بالرنين المغناطيسي لمراكز التشخيص المتقدمة.",
      status: "مفتوح",
      city: "الرياض",
      region: "المملكة العربية السعودية",
      categories: ["أجهزة طبية"],
      endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
      remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, 
    },
  ];*/
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const Offers = await getOffers();
        if (Offers) {
          console.log(Offers);
          setOpportunities(Offers.data);
        } else {
          setOpportunities([]);
        }
      } catch (error) {
        console.error("Error fetching Offers:", error);
      }
    };

    fetchOffers();
  }, []);
  return (
    <section id="latestOpportunities" className="w-full mb-12 py-12  mt-12 rtl">
      <div className="bg-primary-white">
        <div className="container pt-12 pb-12 mx-auto my-0 px-7">
          <div className="mb-12 md:text-right text-center">
            <h1 className="text-4xl font-extrabold mb-4">
              أحدث المناقصات الطبية
            </h1>
            <p className="text-sm">
              اكتشف أحدث المناقصات المتاحة في مجال الأجهزة والمعدات الطبية. سواء
              كنت موردًا للأجهزة الطبية،
              <br className="hidden md:inline" />
              أو مقدمًا لخدمات الصيانة، ستجد هنا فرصًا متنوعة تناسب مهاراتك
              وخبراتك.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {opportunities.map((opportunity) => (
              <div key={opportunity.id} className="w-full">
                <OpportunityCard opportunity={opportunity} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OffersPage;
