import React, { useEffect, useState } from 'react';
import regionsData from "@/app/features/company/data/regions_lite.json";
import citiesData from "@/app/features/company/data/cities_lite.json";

interface CompanyInfoCardProps {
  company: {
    nameAr: string;
    nameEn: string;
    website?: string;
    contactInfo?: string;
    city?: string;
    region?: string;
  };
}
interface City {
  city_id: number;
  region_id: number;
  name_ar: string;
}
interface Region {
  region_id: number;
  name_ar: string;
}
interface Option {
  value: string;
  label: string;
}
const CompanyInfoCard: React.FC<CompanyInfoCardProps> = ({ company }) => {
  const [regions, setRegions] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  useEffect(() => {
    setRegions(
      regionsData.map((region: Region) => ({
        value: region.region_id.toString(), // Convert to string
        label: region.name_ar,
      }))
    );
  }, []);
  useEffect(() => {
    setCities(
      citiesData.map((City: City) => ({
        value: City.city_id.toString(),
        label: City.name_ar,
      }))
    );
  }, []);
  const getCity = (value:string)=>{
    let city = cities.find(item=>parseInt(item.value) === parseInt(value))
    if(city){
      return city.label
    }
    else{return ""}
  }
  const getRegion = (value:string)=>{
    let city = regions.find(item=>parseInt(item.value) === parseInt(value))
    if(city){
      return city.label
    }
    else{return ""}
  }
  return (
      <div className="mt-2">
        <p style={{ fontSize: '14px' }}><strong>{company.nameAr}</strong></p>
        {company.website && (
          <p>
            <a href={company.website} target="_blank" rel="noopener noreferrer" className="text-blue-600">
              {company.website}
            </a>
          </p>
        )}
        {company.contactInfo && <p>{company.contactInfo}</p>}
        <div className={"flex"}>
        {company.city && <p>{getCity(company.city)} - </p>}
        {company.region && <p>{getRegion(company.region)}</p>}
        </div>
      </div>
  );
};

export default CompanyInfoCard;
