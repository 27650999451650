import React from 'react';
import Responsive from './Responsive';

interface LobbyAdminProps {
  // Define any props you want to pass here
  // Example: title?: string;
}

const LobbyAdmin: React.FC<LobbyAdminProps> = () => {
  return (
    <div style={{
      width: '100vw',
      position: 'absolute',
      left: '0',
      top: '0'
    }}> 
      <div>
        <Responsive />
      </div>
    </div>
  );
}

export default LobbyAdmin;
