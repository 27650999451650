import NavPage from "./nav/nav-page";
import SellerLobby from './lobby/seller-lobby'
//import Footer from "../../footer";
function LandingPageSeller() {
    return (
      <div className="flex flex-col min-h-screen" style={{background: "#f8fafa !important",paddingTop:80}}>
     <NavPage 
      isForm={0}
      />
        <SellerLobby />
      </div>
    );
  }
  
  export default LandingPageSeller;