// core/register-schema.ts
import {z} from "zod";

const RegisterSchema = z.object({
  nickName: z.string().min(1, "Username is required"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
});

export default RegisterSchema;
