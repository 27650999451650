import {useState} from "react";
import axios from "axios";
import {getData} from "@/lib/utils";
import api from "@/store/api";

const useLobby = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const getTotaltenderQuotations = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenderQuotations/getTotal`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    const getTotaltenderOpenedQuotations = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenderQuotations/getTotal/Opened`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };



    const getTotaltenderClosed = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenders/getTotal/Closed`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    //gettenderStatus
    const gettenderStatus = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenders/getTotal/ByStatus`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    //gettenderStatus
    const getListtenderQuotations = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenderQuotations/getAll/All`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    //getListtenderQuotationsByOfferId
    const getListtenderQuotationsByOfferId = async (OfferId: string) => {
      setIsLoading(true);
      setError(null);
      try {
        let Token = getData("tokens")?.accessToken
        const response = await api.get(`/web/tenderQuotations/getAll/Offert`, {
          params: { OfferId },
          headers: { 
            'Authorization': `Bearer ${Token}`
          }
        });
        setIsLoading(false);
        return response.data;
      } catch (err) {
        setIsLoading(false);
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            // Offer not found, return null instead of throwing an error
            return null;
          }
          setError(err.response?.data?.message || "Error fetching offer");
        } else {
          setError("An unexpected error occurred");
        }
        throw err;
      }
    };
    //getTotaltenderOpened
    const getTotaltenderOpened = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenderQuotations/getAll/All/Opened`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    //getTotaltenderOpened
    const getTotaltenderAllClosed = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenderQuotations/getAll/All/Closed`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    //getTotalMytenderQuatations
    const getTotalMytenderQuatations = async () => {
        setIsLoading(true);
        setError(null);
        try {
          let Token = getData("tokens")?.accessToken
          const response = await api.get(`/web/tenderQuotations/getAll/All/Mine`,
            {headers: { 
            'Authorization': `Bearer ${Token}`
          }});
          setIsLoading(false);
          return response.data;
        } catch (err) {
          setIsLoading(false);
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
              // Offer not found, return null instead of throwing an error
              return null;
            }
            setError(err.response?.data?.message || "Error fetching offer");
          } else {
            setError("An unexpected error occurred");
          }
          throw err;
        }
      };
    return {getListtenderQuotationsByOfferId,getTotalMytenderQuatations,getTotaltenderAllClosed,getTotaltenderOpened,getTotaltenderQuotations,getTotaltenderOpenedQuotations,getTotaltenderClosed,gettenderStatus,getListtenderQuotations,isLoading, error};
};

export default useLobby;