import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import {ProfileSchema} from "../../profile-schema";
import {getData} from "@/lib/utils";
import api from "@/store/api";

const useProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const registerUser = async (values: z.infer<typeof ProfileSchema>) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.post("/api/profile", values);
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "Error registering user");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  const updateUser = async (
    values: z.infer<typeof ProfileSchema>
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.post(`/web/auth/upsert`, values,
        {
          headers:{
            'Authorization': `Bearer ${Token}`,
          }
        }
      );
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "Error updating profile");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  //id: string
  const getUserProfile = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.get(`/web/authInfo/profile/`,{
        headers:{
          'Authorization': `Bearer ${Token}`,
        }
      });
      setIsLoading(false);
      return response.data; // This might be null if the user doesn't exist
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          // User not found, return null instead of throwing an error
          return null;
        }
        setError(err.response?.data?.message || "Error fetching profile");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  return {registerUser, updateUser,getUserProfile, isLoading, error};
};

export default useProfile;
