import React, { useState } from "react";
import Countdown from "react-countdown";
import {FaCreditCard} from "react-icons/fa";
import { useNavigate } from "react-router-dom"; 
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/app/global-components/card"; // Assurez-vous que le chemin d'importation est correct
import {isEmpty, isUndefined} from 'lodash'
import './styles.css'
import useRegisterCompany from "../features/company/components/hook/useRegisterCompany";
interface Opportunity {
  city: string ;
  region: string;
  categories: any;
  endDate: string | number | Date | undefined;
  id: number;
  title: string;
  category: string;
  location: string;
  //region: string;
  //city: string;
  status: string;
  remainingTime: number;
  companyId	: string;
}

interface OpportunityCardProps {
  opportunity: Opportunity;
}
export interface CompanySchema {
  id: string;
  nameAr	: string;
  nameEn	: string;
  website?: string; // Optional field
  contactInfo?: string;   // Optional field
  city?: string;   // Optional field
  region?: string;   // Optional field
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({opportunity}) => {
  const navigate = useNavigate();
  const {getCompany} = useRegisterCompany();
  const [company,setCompany] = useState<CompanySchema | null>(null)
  const Gotoopportunity=()=>{
    console.log("EEE")
    navigate(`/vuetender/${opportunity.id}`);
  }
  React.useEffect(()=>{
    if(!isUndefined(opportunity.companyId	)){
      getCompany(opportunity.companyId)
      .then(Company =>{
        setCompany(Company.data)
      })      
    }
  },[])
  return (
    <Card
      key={opportunity.id}
      onClick={Gotoopportunity}
      className="md:w-70 bg-white  w-50 h-full flex flex-col transform transition-transform duration-300 hover:scale-105 hover:shadow-xl cursor-pointer"
    >
      <CardHeader className="flex-shrink-0">
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm custom-status font-medium">
            {opportunity.status}
            </div>
          <div className="text-secondary-brightYellow">
            <FaCreditCard size={24} />
          </div>
        </div>
        <div>{company?.nameAr}</div>
      </CardHeader>
      <CardContent className="flex-1 ">
        <CardTitle className="text-xl font-bold text-gray-900 mb-2">
         
          {opportunity.title}
        </CardTitle>
        <div className="text-sm text-gray-600 mb-2">{isEmpty(opportunity.categories)?"":opportunity.categories.map((item: any)=>{return `${item}, `})}</div>
        <div className="text-sm text-gray-600">{opportunity.region}, {opportunity.city}</div>
      </CardContent>
      <CardFooter className="p-4 border-t border-gray-200 bg-gray-50 flex justify-center items-center">
        <div className="flex justify-between items-center text-sm text-gray-500 text-center p-2 border-t border-gray-200 bg-gray-50 rounded-b-lg font-bold">
          <Countdown
            date={opportunity.endDate}
            renderer={({days, hours, minutes, seconds, completed}) => {
              if (completed) {
                return <span>انتهى</span>;
              }
              if (days > 0) {
                return <span>{days + 1} يوم</span>;
              }

              return (
                <span>
                  {hours} ساعة {minutes} دقيقة {seconds} ثانية
                </span>
              );
            }}
          />
        </div>
      </CardFooter>
    </Card>
  );
};

export default OpportunityCard;
