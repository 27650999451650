import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: (lng: string, ns: string) =>
        `/src/features/auth/pages/${ns}/core/i18/${lng}/${ns}.json`,
    },
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
      ],
      caches: ["cookie", "localStorage"],
    },
  });

export default i18n;
