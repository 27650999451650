import ReactDOM from "react-dom";
import App from "./app.tsx";
import "./index.css";
import "./i18n.ts";
import { Provider } from 'react-redux';
import store from './store/store.ts'; 

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);