import React, { useEffect, useState } from 'react';
import useLobby from "../../../hook/useLobby";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { isEmpty } from 'lodash';

// Interface for props
interface WelcomeBoxProps {
  backgroundColor?: string;
  textColor?: string;
}

// Define chart colors
const COLORS = ['#8884d8', '#FFBB28'];

const DoughnutChartComponent: React.FC<WelcomeBoxProps> = ({
  backgroundColor = '#fff', // Default background color
  textColor = '#333', // Default text color
}) => {
  const { gettenderStatus } = useLobby();
  const [tenderStatus, setTenderStatus] = useState<{ status: string; total: number }[]>([]);

  useEffect(() => {
    const getTotaltenderQ = async () => {
      try {
        const Total = await gettenderStatus();
        console.log(Total, "Fetched tender status");

        if (!isEmpty(Total)) {
          // Ensure total is converted to a number
          const formattedData = Total.map((item: { status: string; total: string }) => ({
            status: item.status,
            total: parseInt(item.total, 10),
          }));
          setTenderStatus(formattedData);
        } else {
          // Default data when no response is returned
          setTenderStatus([
            { status: 'منتهية', total: 100 },
            { status: 'غير منتهية', total: 0 },
          ]);
        }
      } catch (error) {
        console.error("Error fetching tender status:", error);
      }
    };

    getTotaltenderQ();
  }, []);

  return (
    <div
      style={{
        padding: '15px 20px',
        borderRadius: '8px',
        backgroundColor,
        color: textColor,
        //boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        height: '100%',
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Tooltip />
          <Pie
            data={tenderStatus}
            dataKey="total"  // Correct key for 'total'
            nameKey="status"  // Correct key for 'status'
            cx="50%"
            cy="50%"
            outerRadius={100} // Outer radius for the doughnut
            innerRadius={70} // Inner radius to create the doughnut effect
            fill="#8884d8"
            label  // Display labels on the chart
          >
            {tenderStatus.map((entry, index) => {
              console.log(entry)
              return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            })}
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{ paddingLeft: '10px' }} // Adjust the padding as needed
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DoughnutChartComponent;
