import React from 'react';
import RGL, { WidthProvider,  Layout } from 'react-grid-layout';
import WelComeBox from './Wedgits/welcome-box'
import TotalAvailableOffer from './Wedgits/total-available-offer'
import TotalOpenedOffer from './Wedgits/total-opened-offer'
import TotalClosedOffer from './Wedgits/total-closed-offer'
import TrenderQuatationsList from './Wedgits/treder-quatations-list'
import TotalOfferperStatus from './Wedgits/total-offer-status'
const ReactGridLayout = WidthProvider(RGL);

interface ExtraLargeGridProps {}

const ExtraLargeGrid: React.FC<ExtraLargeGridProps> = () => {
  const layout: Layout[] = [
    { i: '1', x: 0, y: 0, w: 3, h: 5, static: true }, // Adjust x for RTL
    { i: '2', x: 3, y: 0, w: 3, h: 5, static: true },
    { i: '3', x: 6, y: 0, w: 3, h: 5, static: true },
    { i: '4', x: 9, y: 0, w: 3, h: 5, static: true },
    { i: '5', x: 0, y: 5, w: 3, h: 15, static: true },
    { i: '6', x: 6, y: 5, w: 9, h: 25, static: true },  
  ];

  return (
    <div className="module-list-scroll scrollbar actionSidebar" style={{ direction: 'ltr',padding:"130px 20px" }}>
      <ReactGridLayout
        className="layout"
        layout={layout}
        rowHeight={20}
        isDraggable={false}
        isResizable={false}
      >
        <div style={{ backgroundColor: 'white', zIndex: 3,borderRadius:10 }} key="1">
          <span className="text">
            <TotalClosedOffer/>
          </span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3,borderRadius:10 }} key="2">
          <span className="text"><TotalOpenedOffer/></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3,borderRadius:10 }} key="3">
          <span className="text"><TotalAvailableOffer/></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3,borderRadius:10 }} key="4">
          <span className="text"><WelComeBox/></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3,borderRadius:10 }} key="5">
          <span className="text"><TotalOfferperStatus/></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3,borderRadius:10 }} key="6">
          <span className="text">
          {/*  <TotalOfferpermonth/>*/}
          <TrenderQuatationsList/>            
          </span>
        </div>
      </ReactGridLayout>
    </div>
  );
};

export default ExtraLargeGrid;
