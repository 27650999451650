import LoginSchema from "../../../../@core/schema/login-schema";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import LoginFieldView from "./login-field-view";
import LoginSubmitView from "./login-submit-view";
import useLogin from "@/app/features/auth/presentation/login/hook/use-login"; // Import the new hook
import {useNavigate} from "react-router-dom";
import {storeData} from "@/lib/utils";
import useRegisterCompany from "@/app/features/company/components/hook/useRegisterCompany";

const LoginFormView = () => {
  const navigate = useNavigate();
  const {login, isLoading, error} = useLogin(); // Use the new hook
  const {getCompanyMine} = useRegisterCompany();
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof LoginSchema>) => {
    try {
      const response = await login(values);
      storeData("tokens", response.data.tokens);
      storeData("userInfo", response.data.user);
      getCompanyMine()
      navigate("/");
    } catch (err) {
      // Error is already handled in the hook
      console.error(err);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <LoginFieldView
          name="email"
          label="البريد الإلكتروني"
          placeholder="البريد الإلكتروني"
          inputType="email"
          formControl={form.control}
        />
        <LoginFieldView
          name="password"
          label="كلمة المرور"
          placeholder="كلمة المرور"
          description="على الأقل 8 أحرف."
          inputType="password"
          formControl={form.control}
        />
        {/* {" "}
        <div className="flex items-center">
          <Checkbox
            className="data-[state=checked]:bg-secondary-brightYellow data-[state=checked]:text-textcolor-mediumGray focus-visible:ring-2 focus-visible:ring-secondary-brightYellow"
            id="rememberMe"
          />
          <label htmlFor="rememberMe" className="text-sm mr-1 font-medium">
            تذكرني
          </label>
        </div>{" "}
        */}
        <LoginSubmitView isLoading={isLoading} />
        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default LoginFormView;
