import React from 'react';
import useLobby from "../../../hook/useLobby";
interface TotalAvailableOffersProps {
  backgroundColor?: string;
  textColor?: string;
}

const TotalAvailableOffers: React.FC<TotalAvailableOffersProps> = ({
    backgroundColor = '#fff', // Default background color
    textColor = '#333' // Default text color
}) => {
  const {getTotaltenderOpened} = useLobby();
  const [totalOffers,settotalOffers] = React.useState(0)
  React.useEffect( () => {
    const getTotaltenderQ = async () => {
    try {
      const Total = await getTotaltenderOpened();
      if (Total) {
          console.log(Total,"DDDD")
          //let Cates: ((prevState: never[]) => never[]) | { value: any; label: any; }[] = []
          //Compaies.data.map((item: { id: any; name: any; })=>{ Cates.push({value:item.id,label:item.name}) })
          settotalOffers(Total.total)
      }
      else{
        settotalOffers(0)
      }
  }
  catch (error) {
      console.error("Error fetching Offers:", error);
    }
  };
  getTotaltenderQ();
  },[])
  return (
    <div  style={{
        padding: '0',
        borderRadius: '8px',
        backgroundColor,
        color: textColor,
        //boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        display:"flex",
        alignItems:"center",
        justifyContent:"space-around",
        height:"100%"
      }}>
        <h2 style={{ margin: '0 0 0 0',fontSize:'3em' }}>
            {totalOffers}
        </h2>
        <h2 className="text-xl font-semibold text-right">
            مجموع الفرص المتاحة 
        </h2>
     
    </div>
  );
};

export default TotalAvailableOffers;