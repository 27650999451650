import { combineReducers } from 'redux';
import authReducer, { AuthState } from './authSlice'; // Import AuthState here

const rootReducer = combineReducers({
  auth: authReducer, // Add your slice reducers here
  
  // other reducers can go here
});

// If you want to create a RootState type
export type RootState = {
  auth: AuthState; // Use AuthState here
  // other states can go here
};

export default rootReducer;
