import React from "react";

interface BackdropProps {
  toggleSidebar: (open: boolean) => void;
}

const Backdrop: React.FC<BackdropProps> = ({toggleSidebar}) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen z-10 bg-primary-purple opacity-80"
      onClick={() => toggleSidebar(false)}
    ></div>
  );
};

export default Backdrop;
