import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import RegisterSchema from "@/app/features/auth/@core/schema/register-schema";
import api from "@/store/api";

const useRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const register = async (values: z.infer<typeof RegisterSchema>) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post("/web/auth/register",
        values
      );
      setIsLoading(false);
      console.log(response.data);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message || "An error occurred during registration"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  return {register, isLoading, error};
};

export default useRegister;
