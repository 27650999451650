import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import ForgotPasswordSchema from "@/app/features/auth/@core/schema/forgot-password-schema";

const useForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendForgotPasswordEmail = async (
    values: z.infer<typeof ForgotPasswordSchema>
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post("/api/forgot-password", values);
      setIsLoading(false);
      return response.data; // Adjust this based on your API response
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while sending the email"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  return {sendForgotPasswordEmail, isLoading, error};
};

export default useForgotPassword;
