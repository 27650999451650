// src/app/features/auth/components/arabic-date-picker.tsx

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/app/global-components/popover"; // Adjust the import path as necessary
import {Button} from "@/app/global-components/button"; // Adjust the import path as necessary
import {Calendar} from "@/app/global-components/calendar"; // Adjust the import path as necessary
import {CiCalendar} from "react-icons/ci"; // Adjust the import path as necessary
import {format} from "date-fns"; // Ensure you have date-fns installed
import {ar} from "date-fns/locale"; // Import Arabic locale

interface ArabicDatePickerProps {
  date: Date | null;
  setDate: (date: Date | null) => void;
}

const ArabicDatePicker: React.FC<ArabicDatePickerProps> = ({date, setDate}) => {
  const handleDateSelect = (selectedDate: Date | undefined) => {
    setDate(selectedDate || null); // Set date to null if selectedDate is undefined
  };

  const datePicker = Date.now();

  // Create a new Date object from the timestamp
  const dateObject = new Date(datePicker);

  // Get the year using getFullYear()
  const year = dateObject.getFullYear();


  return (
    <div className="w-full">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={`w-full justify-start text-left font-normal ${
              !date ? "text-muted-foreground" : ""
            }`}
          >
            <CiCalendar className="ml-2 h-4 w-4" />
            {date ? (
              format(date, "PPP", {locale: ar})
            ) : (
              <span>اختر تاريخًا</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          side="top"
          align="center"
          className="w-full flex justify-center items-center p-0"
        >
          <Calendar
            className="bg-primary-gray w-full flex justify-center items-center"
            mode="single"
            captionLayout="dropdown-buttons"
            selected={date ? date : undefined} // Convert null to undefined
            onSelect={handleDateSelect}
            hideHead={false}
            initialFocus
            fromYear={1930}
            toYear={year + 1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ArabicDatePicker;
