import {Button} from "@/app/global-components/button";
import {ImSpinner2} from "react-icons/im";

interface ForgotPasswordSubmitViewProps {
  isLoading: boolean;
}

const ForgotPasswordSubmitView: React.FC<ForgotPasswordSubmitViewProps> = ({
  isLoading,
}) => {
  return (
    <div>
      <Button
        className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black w-full py-2 px-4 text-sm"
        type="submit"
        disabled={isLoading} // Disable button while loading
      >
        {isLoading ? (
          <>
            جاري الإرسال...
            <span className="mr-2 animate-spin">
              <ImSpinner2 />
            </span>
          </>
        ) : (
          "أرسل"
        )}
      </Button>
    </div>
  );
};

export default ForgotPasswordSubmitView;
