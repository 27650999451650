// src/app/features/auth/pages/change-password-page.tsx
import ChangePasswordForm from "./components/change-password-form-view";
import {Link} from "react-router-dom";

export default function ChangePasswordPage() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-4 sm:p-6">
      <section className="w-full max-w-md bg-primary-white p-5 shadow-xl rounded-md sm:max-w-lg">
        <div className="flex flex-col mb-4">
          <h1 className="text-2xl font-semibold sm:text-3xl">
            تغيير كلمة المرور
          </h1>
          <p className="text-sm text-textcolor-mediumGray sm:text-base">
            قم بإدخال كلمة المرور القديمة وكلمة المرور الجديدة لتغيير كلمة
            المرور الخاصة بك
          </p>
        </div>
        <ChangePasswordForm />
        <p className="text-sm text-textcolor-mediumGray mt-4">
          العودة إلى{" "}
          <span className="underline text-secondary-brightYellow cursor-pointer">
            <Link to="/profile">الملف الشخصي</Link>
          </span>
        </p>
      </section>
    </main>
  );
}
