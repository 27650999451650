// src/app/features/offer/hooks/useOffer.ts

import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import {TenderSchema} from "../../tender-schema";
import {tenderQuotationSchema} from "../view/tenderQuotationSchema";
import {getData} from "@/lib/utils";
import api from "@/store/api";

const useOffer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  


  const createOffer = async (values: z.infer<typeof TenderSchema>) => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.post("/web/tenders/upsert", values,
        {headers: { 
          'Authorization': `Bearer ${Token}`
        }}
      );
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "Error creating offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  const updateOffer = async (
    id: string,
    values: z.infer<typeof TenderSchema>
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.put(`/api/offers/${id}`, values);
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "Error updating offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  const getOffer = async (id: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(`/web/tenders/get?id=${id}`);
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          // Offer not found, return null instead of throwing an error
          return null;
        }
        setError(err.response?.data?.message || "Error fetching offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  const getOffers = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(`/web/tenders/getAll?pageSize=10&pageNumber=1&withPaging=false`);
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          // Offer not found, return null instead of throwing an error
          return null;
        }
        setError(err.response?.data?.message || "Error fetching offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };
  const getMyOffers = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.get(`/web/tenders/getMy?pageSize=10&pageNumber=1&withPaging=false`, {headers: { 
        'Authorization': `Bearer ${Token}`
      }});
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          // Offer not found, return null instead of throwing an error
          return null;
        }
        setError(err.response?.data?.message || "Error fetching offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };
  const getcategories = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.get(`/web/categories/getAll?pageSize=10&pageNumber=1&withPaging=false`,
        {headers: { 
        'Authorization': `Bearer ${Token}`
      }});
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          // Offer not found, return null instead of throwing an error
          return null;
        }
        setError(err.response?.data?.message || "Error fetching offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };
  const getcompanies = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.get(`/web/companies/getAll?pageSize=10&pageNumber=1&withPaging=false`,
        {headers: { 
        'Authorization': `Bearer ${Token}`
      }});
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          // Offer not found, return null instead of throwing an error
          return null;
        }
        setError(err.response?.data?.message || "Error fetching offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };
  const sendOffertRequest = async (values: z.infer<typeof tenderQuotationSchema>) => {
    setIsLoading(true);
    setError(null);
    try {
      let Token = getData("tokens")?.accessToken
      const response = await api.post("/web/tenderQuotations/upsert",values,
        {headers: { 
          'Authorization': `Bearer ${Token}`
        }}
      );
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data?.message || "Error creating offer");
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };  
  return {createOffer, updateOffer, getOffer,getOffers,getcategories,getcompanies,sendOffertRequest,getMyOffers, isLoading, error};
};

export default useOffer;
