import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white text-black py-10 mt-10">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16">
        <div className="flex flex-wrap justify-between">
          {/* Company Info */}
          <div className="w-full md:w-1/3 mb-8 md:mb-0 ">
          <h1 className="ml-4 text-3xl font-extrabold  bg-clip-text bg-gradient-to-r from-black to-gray-400">
  Med<span className="text-black">e</span>x
</h1>
            <p className="text-gray-700">
              نحن في ميدكس نسعى إلى تحويل تجربة شراء المستلزمات الطبية إلى رحلة
              سلسة وموفرة للوقت والجهد.
            </p>
          </div>

          {/* Quick Links */}
          <div className="w-full md:w-1/3 mb-8 md:mb-0 px-3">
            <h3 className="text-lg font-semibold mb-4">روابط سريعة</h3>
            <ul className="text-gray-700">
              <li className="mb-2">
                <a href="/AboutUs" className="hover:text-yellow-500">
                  من نحن
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="w-full md:w-1/3">
            <h3 className="text-lg font-semibold mb-4">تواصل معنا</h3>
            <p className="text-gray-700 mb-2">
              <a href="mailto:contact@medex2b.com" className="hover:text-yellow-500">
              contact@medex2b.com
              </a>
            </p>
           {/* <p className="text-gray-700">
              الهاتف:{" "}
              <a href="tel:+123456789" className="hover:text-yellow-500">
                +123456789
              </a>
            </p>
            */}
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-8 text-center text-gray-500">
          <p>&copy; 2024 ميدكس. جميع الحقوق محفوظة.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
