import ConfirmSchema from "../../../../@core/schema/confirm-schema";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import ConfirmFormFieldView from "./confirm-form-field-view";
import ConfirmSubmitView from "./confirm-submit-view";
import useConfirm from "@/app/features/auth/presentation/confirm/components/hook/use-confirm"; // Import the new hook
import {useNavigate} from "react-router-dom";

const ConfirmFormView = () => {
  const navigate = useNavigate();
  const {confirm, isLoading, error} = useConfirm(); // Use the new hook

  const form = useForm<z.infer<typeof ConfirmSchema>>({
    resolver: zodResolver(ConfirmSchema),
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof ConfirmSchema>) => {
    try {
      const response = await confirm(values);
      // Handle successful confirmation (e.g., navigate to a success page)
      navigate("/");
      console.log(response);
    } catch (err) {
      // Error is already handled in the hook
      console.error(err);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <ConfirmFormFieldView
          name="code"
          label="الرمز"
          placeholder="أدخل الرمز"
          inputType="text"
          formControl={form.control}
        />
        <ConfirmSubmitView isLoading={isLoading} />
        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default ConfirmFormView;
