import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./i18n";
import RegisterPage from "./app/features/auth/presentation/register/register-page";
import LoginPage from "./app/features/auth/presentation/login/login-page";
import ForgotPasswordPage from "./app/features/auth/presentation/forgot-password/forgot-password-page";
import ConfirmPage from "./app/features/auth/presentation/confirm/confirm-page";
import LandingPage from "./app/features/landingPage/presentation/landing-page";
import LandingPageBuyer from "./app/features/landingPage/presentation/landing-page-Buyer";
import LandingPageSeller from "./app/features/landingPage/presentation/landing-page-seller";
import AboutUsPage from "./app/features/AboutUsPage";
import CompanyPage from "./app/features/company/company-page";
import ProfilePage from "./app/features/profile/profile-page";
import ChangePasswordPage from "./app/features/auth/presentation/change-password/change-password-page";
import ResetPasswordPage from "./app/features/auth/presentation/reset-password/reset-password-page";
import TenderPage from "./app/features/tender/tender-page";
import TenderSquarePage from "./app/features/tender/tenders-square-page";
import QuatationTendersPage from "./app/features/tender/tenders-quatations-page";
import TendersPage from "./app/features/tender/tenders-page";
import TenderMinePage from "./app/features/tender/tenders-mine-page";
import TenderCard from "./app/features/tender/tender-card";
import SendOffert from "./app/features/tender/send-offer";
import { storeData} from "@/lib/utils";
import { isNull, isUndefined } from "lodash";
import React from "react";
import useLogin from "./app/features/auth/presentation/login/hook/use-login";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from './store/type';
import { getMyCompany, updateAuthUser } from "./store/authSlice";
import ProtectedRoute from "./app/ProtectedRoute";
import useRegisterCompany from "./app/features/company/components/hook/useRegisterCompany";
function App() {
  const dispatch = useDispatch();
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const userRole = isUndefined(authUser?.role)?"":authUser?.role;
  const [loading, setLoading] = React.useState(false)
  console.log(authUser)
  const {getMe} = useLogin();
  const {getCompanyMine} = useRegisterCompany();
  React.useEffect(()=>{
    const checkCurrent = async () => {
      if(!isNull(authUser)){
      try {
        setLoading(true)
        const response = await getMe();
        if(response.isSuccess){
          storeData("tokens", response.data.tokens);
          storeData("userInfo", response.data.user);
          setLoading(false)
          getCompanyMine()
          

        }
      } catch (error) {
        console.log(error)
        dispatch(updateAuthUser(null)); 
        dispatch(getMyCompany(null)); 
        localStorage.removeItem("tokens")
        localStorage.removeItem("userInfo")
        setLoading(false)
      }
    }else{
      try {
        setLoading(true)
        const response = await getMe();
        if(response.isSuccess){
          storeData("tokens", response.data.tokens);
          storeData("userInfo", response.data.user);
          setLoading(false)
        }
      } catch (error) {
        dispatch(updateAuthUser(null)); 
        dispatch(getMyCompany(null)); 
        localStorage.removeItem("tokens")
        localStorage.removeItem("userInfo")
        setLoading(false)
      }
    }
    };
    checkCurrent();  
  },[])
  if(isNull(authUser)){

  }
  return (
    <>
    {
      loading === false?      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={userRole ==="Buyer"?<LandingPageBuyer />:userRole==="Seller"?<LandingPageSeller />:<LandingPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
          <Route path="/changePassword" element={<ChangePasswordPage />} />
          <Route path="/confirm" element={<ConfirmPage />} />
          <Route path="/resetPassword" element={<ResetPasswordPage />} />
          <Route path="/AboutUs" element={<AboutUsPage/>} />
          <Route path="/company" element={ <ProtectedRoute>
            <CompanyPage />
          </ProtectedRoute>} />
          <Route path="/quatation/:id" element={ <ProtectedRoute>
            <SendOffert />
          </ProtectedRoute>} />
          <Route path="/profile/:id" element={<ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>} />
          <Route path="/tender" element={<ProtectedRoute><TenderPage /></ProtectedRoute>} />
          <Route path="/QuatationtendersrePage" element={<ProtectedRoute><QuatationTendersPage /></ProtectedRoute>} />
          <Route path="/TenderSquatendersrePage" element={<ProtectedRoute><TenderSquarePage /></ProtectedRoute>} />
          <Route path="/tenders" element={userRole==="Buyer"?<ProtectedRoute><TenderMinePage /></ProtectedRoute>:<TendersPage />} />
          <Route path="/vuetender/:id" element={<TenderCard />} />
        </Routes>
      </BrowserRouter>
      :"ss"
    }
    </>
  );
}

export default App;
