// src/app/features/auth/components/change-password-submit-view.tsx
import {Button} from "@/app/global-components/button";
import {ImSpinner2} from "react-icons/im";

interface ChangePasswordSubmitViewProps {
  isLoading: boolean;
}

const ChangePasswordSubmitView: React.FC<ChangePasswordSubmitViewProps> = ({
  isLoading,
}) => {
  return (
    <div>
      <Button
        className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black w-full py-2 px-4 text-sm"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            جاري تغيير كلمة المرور...
            <span className="mr-2 animate-spin">
              <ImSpinner2 />
            </span>
          </>
        ) : (
          "تغيير كلمة المرور"
        )}
      </Button>
    </div>
  );
};

export default ChangePasswordSubmitView;
