import React, { useState, useEffect } from "react";
import useOffer from "../hook/useTender";
import { useParams, useNavigate } from "react-router-dom";
import { isEmpty, isNull } from 'lodash';
import moment from "moment";
import { Link as LinkTo } from "react-router-dom";
import { RootState } from "@/store/type";
import { useSelector } from "react-redux";
import useRegisterCompany from "@/app/features/company/components/hook/useRegisterCompany";
import CompanyInfoCard from './CompanyInfoCard';
import regionsData from "@/app/features/company/data/regions_lite.json";
import citiesData from "@/app/features/company/data/cities_lite.json";
import ListofQuatations from './list-of-Quatations'
import { Alert } from "@mui/material";
interface Product {
  item: string;
  quantity: number;
  attachment: string; // Assuming it's a file name or URL
}
export interface CompanySchema {
  id: string;
  nameAr	: string;
  nameEn	: string;
  website?: string; // Optional field
  contactInfo?: string;   // Optional field
  city?: string;   // Optional field
  region?: string;   // Optional field
}
interface Offer {
  title: string;
  minValue: number;
  value: number;
  endDate: string;
  deliverDate: string;
  type: string;
  status: string;
  categories: string[];
  region: string;
  city: string;
  fileName: string;
  fileDescription: string;
  attachmentName: string;
  attachmentDescription: string;
  receiveDocumentsType: string;
  contactInfo: string;
  products: Product[]; // Include products array
}
interface City {
  city_id: number;
  region_id: number;
  name_ar: string;
}
interface Region {
  region_id: number;
  name_ar: string;
}
interface Option {
  value: string;
  label: string;
}
const TenderFormView: React.FC = () => {
  const { id } = useParams();
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  
  const { getOffer, isLoading, error } = useOffer();
  const { getCompany } = useRegisterCompany();
  const [offer, setOffer] = useState<Offer | null>(null);
  const [company,setCompany] = useState<CompanySchema | null>(null)
  const navigate = useNavigate();
  const [regions, setRegions] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  useEffect(() => {
    setRegions(
      regionsData.map((region: Region) => ({
        value: region.region_id.toString(), // Convert to string
        label: region.name_ar,
      }))
    );
  }, []);
  useEffect(() => {
    setCities(
      citiesData.map((City: City) => ({
        value: City.city_id.toString(),
        label: City.name_ar,
      }))
    );
  }, []);
  const getCity = (value:string)=>{
    let city = cities.find(item=>parseInt(item.value) === parseInt(value))
    if(city){
      return city.label
    }
    else{return ""}
  }
  const getRegion = (value:string)=>{
    let city = regions.find(item=>parseInt(item.value) === parseInt(value))
    if(city){
      return city.label
    }
    else{return ""}
  }
  useEffect(() => {
    const fetchOfferCard = async () => {
      if (id) {
        try {
          const offerData = await getOffer(id);
          if (offerData) {
            setOffer(offerData.data);
            const Company = await getCompany(offerData.data.companyId	)
            setCompany(Company.data)
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log(error)
         // navigate("/");
        }
      }
    };

    fetchOfferCard();
  }, [id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading offer</div>;
  }

  if (!offer) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-5 w-full md:flex-row space-x-4">
        <section className="w-full">
          <div className="p-6">
            <h3 className="tracking-tight text-xl font-bold mb-4">طلب عرض سعر</h3>
            <div className="p-6 pt-0">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                {/* Existing offer details */}
                <div className="space-y-1">
                {company ? (
                  <div className="py-6">
                    <CompanyInfoCard company={company} />
                  </div>
                  ) : (
                    <>الشركة: غير متوفر</>
                  )}
                </div>
                <div className="py-6">
                <div className="mt-2" >
                  <p><strong>العنوان: </strong>{offer.title} </p>
                  <p><strong>المنطقة: </strong>{getRegion(offer.region)} </p>
                  <p><strong>المدينة: </strong>{getCity(offer.city)} </p>
                  <p><strong>معلومات الاتصال: </strong> 
                  <a href={`mailto:${offer.contactInfo}`} target="_blank" rel="noopener noreferrer" className="text-blue-600">
                    {offer.contactInfo}
                  </a> 
                  </p>
                </div>
                </div>
                
              {/* <div className="mt-2">
                <p><strong>الحد الأدنى للقيمة: </strong>{offer.minValue} </p>
                </div>*/}
                <div className="mt-2">
                <p><strong>القيمة: </strong>{offer.value} </p>
                 </div>
                <div className="mt-2">
                <p><strong>آخر موعد لتقديم العروض: </strong>{moment(offer.endDate).format("YYYY-MM-DD")} </p>
                </div>
                <div className="mt-2">
                <p><strong>تاريخ فتح العروض: </strong>{moment(offer.deliverDate).format("YYYY-MM-DD")} </p>
                </div>
               {/* <div className="mt-2">
                <p><strong>النوع: </strong>{offer.type} </p>
                </div>
                */}
                <div className="mt-2">
                <p><strong>الحالة: </strong>{offer.status} </p>
                </div>
                <div className="mt-2">
                <p><strong>الفئات: </strong>{isEmpty(offer.categories) ? "" : offer.categories.join(", ")} </p>
                </div>
                <div className="mt-2">
                <p><strong>نوع استقبال المستندات: </strong>{offer.receiveDocumentsType} </p>
                </div>
                <div className="mt-2">
                <p><strong>اسم الملف: </strong>{offer.fileName} </p>
                </div>
                <div className="mt-2">
                <p><strong>وصف الملف: </strong>{offer.fileDescription} </p>
                </div>
                <div className="mt-2">
                <p><strong>اسم الملحق: </strong>{isEmpty(offer.attachmentName) ? "غير متوفر" : offer.attachmentName} </p>
                </div>
                <div className="mt-2">
                <p><strong>وصف الملحق: </strong>{isEmpty(offer.attachmentDescription) ? "غير متوفر" : offer.attachmentDescription} </p>
                </div>
                <div className="mt-2">
                <p><strong>الملحق مطلوب: </strong>{offer.attachmentDescription ? "نعم" : "لا"} </p>
                </div>
              </div>

              {/* Products Table */}
              {offer.products && offer.products.length > 0 && (
                <div className="my-6">
                  <h2 className="text-lg font-semibold">البنود</h2>
                  <table className="min-w-full table-auto">
                    <thead>
                      <tr>
                      <th className="border border-gray-300 p-2 text-right">البند</th>
                <th className="border border-gray-300 p-2 text-right">الكمية</th>
                <th className="border border-gray-300 p-2 text-right">المرفق</th>
                      </tr>
                    </thead>
                    <tbody>
                      {offer.products.map((product, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2">{product.item}</td>
                          <td className="border px-4 py-2">{product.quantity}</td>
                          <td className="border px-4 py-2">
                            {product.attachment ? (
                              <a href={product.attachment} target="_blank" rel="noopener noreferrer">
                                {product.attachment}
                              </a>
                            ) : (
                              "غير متوفر"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Pricing offer button */}
              {!isNull(authUser) ? 
              authUser.role === "Seller"?
              (
                <>
                {isEmpty(company)?
               <Alert severity="error">
                لتمكينك من تقديم عرض أسعار، يرجى تعبئة معلومات شركتكم
               </Alert>
                :                 
                <LinkTo
                  className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black w-full py-2 px-4 text-sm"
                  to={`/quatation/${id}`}
                  style={{ justifyContent: "center" }}
                >
                  تقديم عرض تسعير
                </LinkTo>
                }
              </>
              )
              :
              (
               <ListofQuatations offerID={id}/>
              )
              : (
                ""
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TenderFormView;


