// QuotationDetailsModal.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Box } from '@mui/material';
import { isNull } from 'lodash';
//import { useSelector } from 'react-redux';
//import { RootState } from '@/store/type';

interface QuotationDetailsModalProps {
  open: boolean;
  onClose: () => void;
  quotation: any | null;
}

const QuotationDetailsModal: React.FC<QuotationDetailsModalProps> = ({ open, onClose, quotation }) => {
  if (!quotation) return null;
  //const myCompany = useSelector((state: RootState) => state.auth.myCompany);
  const totalPrice = quotation.products.reduce((acc :any, product:any) => acc + product.unitPrice * product.quantity, 0);
  const getDAte = (days:string)=>{
    switch(days){
      case "7":return "7 أيام"
      case "14":return "14 أيام"
      case "30":return "30 أيام"
      default:return "7 أيام"
    }
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xl"} fullWidth>
       <DialogTitle>تفاصيل عرض السعر</DialogTitle>
      <DialogContent>
      <Typography variant="h6" align="right">اسم الشركة:</Typography>
      <Typography variant="body1" align="right" sx={{ mb: 3 }}>
      {quotation.company?.nameAr}
      </Typography>
      <Typography mb={1}>الحالة: {quotation.status}</Typography>
     <div>
     <Typography variant="h6" align="right">قائمة المنتجات</Typography>
     <Box sx={{ overflowX: 'auto', mt: 2 }}>
      <TableContainer sx={{ maxWidth: '100%', borderRadius: 2, overflow: 'hidden' }}>
        <Table sx={{ minWidth: 650, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell align="right">اسم البند</TableCell>
              <TableCell align="right">الكمية</TableCell>
              <TableCell align="right">سعر الوحدة</TableCell>
              <TableCell align="right">رفع ملف</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quotation.products.map((product :any, index:number) => (
              <TableRow key={index}>
                <TableCell align="right">
                  {product.unitName}  
                </TableCell>
                <TableCell align="right">
                {product.quantity}
                </TableCell>
                <TableCell align="right">
                {product.unitPrice}
                </TableCell>
                <TableCell align="right">
                {product.attachment}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      {/* ملخص إجمالي السعر */}
<Typography variant="h6" align="right">ضريبة القيمة المضافة: {(totalPrice*0.15).toFixed(2)} ريال سعودي</Typography>
<Typography variant="h6" align="right">ملخص إجمالي السعر: {totalPrice.toFixed(2)} ريال سعودي</Typography>
<Grid container spacing={2}> 
        <Grid item xs={12} lg={6}>
        <Typography mb={1}>مدة التسليم المقترحة (أيام): {isNull(quotation.deliverDays) ? "" :quotation.deliverDays}</Typography>
        {quotation.deliverDays}
        </Grid>
        <Grid item xs={12} lg={6}>
        <Typography mb={1}>مدة صلاحية عرض السعر (بعد التقديم): {isNull(quotation.DeadLineDate) ? "" :getDAte(quotation.DeadLineDate)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
      <Typography mb={1}>ماهي طريقة الدفع ؟: {isNull(quotation.paymentMethod) ? "" :quotation.paymentMethod === "1"?"دفع مباشر":"دفع بالاجل (للحصول على هذه الخدمة يتوجب عليك التواصل مع ميدكس بعد قبول عرض السعر)"}</Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
      <Typography mb={1}>جهات الاتصال: {isNull(quotation.contactMethod) ? "" :quotation.contactMethod === "1"?"البريد الإلكتروني": "الهاتف"}</Typography>
      </Grid>
     </div>
      
      
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          إغلاق
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuotationDetailsModal;
