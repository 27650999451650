import NavPage from "./nav/nav-page";
import BuyerLobby from './lobby/buyer-lobby'
//import Footer from "../../footer";
function LandingPage() {
    return (
      <div className="flex flex-col min-h-screen" style={{background: "#f8fafa !important",paddingTop:80}} >
       <NavPage 
      isForm={0}
      />
        <BuyerLobby />
      </div>
    );
  }
  
  export default LandingPage;