import React from "react";
import {ProfileSchema} from "../../profile-schema"; // Import the profile schema
import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {RadioGroup, RadioGroupItem} from "@/app/global-components/radio-group";
import ArabicDatePicker from "@/app/global-components/arbic-date-picker";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import {Input} from "@/app/global-components/input";
import {ComboboxView} from "@/app/global-components/combobox-view";

interface ProfileFieldViewProps {
  name: FieldPath<z.infer<typeof ProfileSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: "text" | "radio" | "date" | "combobox"; // Add date type
  options?: {value: string; label: string}[]; // Options for radio buttons
  formControl: Control<z.infer<typeof ProfileSchema>>;
}

const ProfileFieldView: React.FC<ProfileFieldViewProps> = ({
  name,
  label,
  placeholder,
  inputType,
  options,
  formControl,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem className="mb-4">
          <FormLabel className="mb-2 text-xs lg:text-base">{label}</FormLabel>
          <FormControl>
            {inputType === "radio" && options ? (
              <RadioGroup onValueChange={field.onChange}>
                <div className="flex flex-row gap-3 items-center justify-end">
                  {options.map((option) => (
                    <label
                      key={option.value}
                      className="flex items-center mb-2"
                    >
                      <RadioGroupItem
                        checked={field.value === option.value}
                        value={option.value}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </RadioGroup>
            ) : inputType === "date" ? (
              <div className="flex items-center">
                <FormLabel className="mb-2 text-xs lg:text-base"></FormLabel>
                <ArabicDatePicker
                  date={field.value ? new Date(field.value) : null}
                  // Convert string to Date
                  setDate={(date) => {
                    if (date) {
                      // Convert date to local date string (YYYY-MM-DD)
                      const localDateString = date.toLocaleDateString("en-CA");
                      field.onChange(localDateString);
                    } else {
                      field.onChange(null);
                    }
                  }}
                />
              </div>
            ) : inputType === "combobox" && options ? (
              <ComboboxView
                placeholder={placeholder}
                options={options}
                value={field.value ? field.value : ""}
                onChange={(value) => field.onChange(value)}
              />
            ) : (
              <Input
                {...field}
                className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs flex items-center md:text-base"
                type={inputType || "text"}
                value={typeof field.value === "string" ? field.value : ""}
              />
            )}
          </FormControl>
          <FormMessage className="text-secondary-brightYellow text-xs sm:text-sm" />
        </FormItem>
      )}
    />
  );
};

export default ProfileFieldView;
