import ConfirmFormView from "./components/views/confirm-form-view";

export default function ConfirmPage() {
  const email = sessionStorage.getItem("email");

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-4 sm:p-6">
      <section className="w-full max-w-md bg-primary-white p-5 shadow-xl rounded-md sm:max-w-lg">
        <h1 className="text-2xl font-semibold">تأكيد البريد الإلكتروني</h1>

        {email && (
          <p className="text-sm xs:text-base text-textcolor-mediumGray mb-4">
            ستتلقى رمز تفعيل على{" "}
            <span className="text-secondary-brightYellow text-xs font-semibold">
              {email}
            </span>
          </p>
        )}

        <ConfirmFormView />
      </section>
    </main>
  );
}
