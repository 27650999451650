import React from "react";
import CompanySchema from "../../company-schema";
import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {RadioGroup, RadioGroupItem} from "@/app/global-components/radio-group";
import defaultImage from "@/assets/default.png";
import ArabicDatePicker from "../../../../global-components/arbic-date-picker";
import ImageUploaderView from "../../../../global-components/image-uploader-view";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import {Input} from "@/app/global-components/input";
import {Textarea} from "@/app/global-components/textarea";
import {ComboboxView} from "@/app/global-components/combobox-view";

interface CompanyFieldViewProps {
  name: FieldPath<z.infer<typeof CompanySchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: "text" | "file" | "textarea" | "radio" | "date" | "combobox"; // Add date type
  options?: {value: string; label: string}[]; // Options for radio buttons
  formControl: Control<z.infer<typeof CompanySchema>>;
}

const CompanyFieldView: React.FC<CompanyFieldViewProps> = ({
  name,
  label,
  placeholder,
  inputType,
  options,
  formControl,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem className="mb-4">
          <FormLabel className="mb-2 text-xs lg:text-base">{label}</FormLabel>
          <FormControl>
            {inputType === "file" ? (
              <ImageUploaderView
                placeholder={placeholder}
                defaultImage={defaultImage}
                field={field} // Pass the field prop to ImageUploader
              />
            ) : inputType === "textarea" ? (
              <Textarea
                {...field}
                className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs md:text-base"
                placeholder={placeholder}
                value={typeof field.value === "string" ? field.value : ""}
              />
            ) : inputType === "radio" && options ? (
              <RadioGroup onValueChange={field.onChange}>
                <div className="flex flex-row gap-3 items-center justify-end">
                  {options.map((option) => (
                    <label
                      key={option.value}
                      className="flex items-center mb-2"
                    >
                      <RadioGroupItem value={option.value} className="mr-2" />
                      {option.label}
                    </label>
                  ))}
                </div>
              </RadioGroup>
            ) : inputType === "date" ? (
              <ArabicDatePicker
                date={field.value ? new Date(field.value) : null}
                // Convert string to Date
                setDate={(date) => {
                  if (date) {
                    // Convert date to local date string (YYYY-MM-DD)
                    const localDateString = date.toLocaleDateString("en-CA");
                    field.onChange(localDateString);
                  } else {
                    field.onChange(null);
                  }
                }}
              />
            ) : inputType === "combobox" && options ? (
              <ComboboxView
                placeholder={placeholder}
                options={options}
                value={field.value ? field.value : "منطقة"}
                onChange={(value) => field.onChange(value)}
              />
            ) : (
              <Input
                {...field}
                className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs flex items-center md:text-base"
                placeholder={placeholder}
                type={inputType || "text"}
                value={typeof field.value === "string" ? field.value : ""}
              />
            )}
          </FormControl>
          <FormMessage className="text-secondary-brightYellow text-xs sm:text-sm" />
        </FormItem>
      )}
    />
  );
};

export default CompanyFieldView;
