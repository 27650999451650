// src/app/features/auth/hook/useChangePassword.ts
import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import ChangePasswordSchema from "../../../@core/schema/chagne-password-schema";

const useChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const changePassword = async (
    values: z.infer<typeof ChangePasswordSchema>
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post("/api/change-password", {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message || "حدث خطأ أثناء تغيير كلمة المرور"
        );
      } else {
        setError("حدث خطأ غير متوقع");
      }
      throw err;
    }
  };

  return {changePassword, isLoading, error};
};

export default useChangePassword;
