import ProfileFormView from "./components/view/profile-form-view"; // Update the path as needed
import EditProfileFormView from "./components/view/edit-profile-form-view"; // Update the path as needed
//import {getData} from "@/lib/utils";
import NavPage from "../landingPage/presentation/nav/nav-page";
import { RootState } from "@/store/type";
import { useSelector } from "react-redux";
import Footer from "../footer";

export default function ProfilePage() {
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const userId = authUser?.id;
  

  return (
    <div className="flex flex-col min-h-screen" style={{background: "#f8fafa !important"}}>
<NavPage 
      isForm={0}
      />
    <div className="xs:flex xs-flex-col xs:justify-center sm:shadow-lg" style={{overflow:"auto",paddingTop:160}}>
      <main className="flex min-h-screen  items-center justify-center w-full sm:w-3/5 flex-col" style={{padding:"130xp 0px"}}>
      {
        userId ? 
        <section className="w-full  max-w-xs xs:justify-center bg-primary-white p-5 rounded-md xs:max-w-5xl py-6">
          <div className="flex flex-col">
            <h1 className="sm:text-2xl text-base font-semibold ">
            معلومات المستخدم
            </h1>
            <p className="mb-4 text-secondary-brightYellow text-sm sm:text-base">
              للتسجيل في النظام
            </p>
          </div>
          <EditProfileFormView />
          {/* Use the UserProfileFormView for user registration */}
        </section>
        :
        <section className="w-full  max-w-xs xs:justify-center bg-primary-white p-5 rounded-md xs:max-w-5xl py-6">
          <div className="flex flex-col">
            <h1 className="sm:text-2xl text-base font-semibold ">
              تسجيل المستخدم
            </h1>
            <p className="mb-4 text-secondary-brightYellow text-sm sm:text-base">
              للتسجيل في النظام
            </p>
          </div>
          <ProfileFormView />{" "}
          {/* Use the UserProfileFormView for user registration */}
        </section>
      }
        
      </main>
    </div>
    <Footer />
    </div>
  );
}
