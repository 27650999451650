import { Chip } from '@mui/material';

const StatusChip = ({ status }: { status: string }) => {
  let chipProps;

  switch (status) {
    case "Opened":
      chipProps = {
        label: "Opened",
        style: {
          backgroundColor: "#e3f2fd",
          color: "#3f77a4",
          fontWeight: 600,
          font:'13px !important',
          borderRadius: 30,
          height:'25px !important'
        },
      };
      break;
    case "Finished":
      chipProps = {
        label: "Finished",
        style: {
          backgroundColor: "#ffccbc",
          color: "#bf360c",
          fontWeight: 600,
          font:'13px !important',
          borderRadius: 30,
          height:'25px !important'
        },
      };
      break;
    case "Accepted":
      chipProps = {
        label: "Accepted",
        style: {
          backgroundColor: "#cdeeeb",
          color: "#00a046;",
          fontWeight: 600,
          font:'13px !important',
          borderRadius: 30,
          height:'25px !important'
        },
      };
      break;
    case "Closed":
      chipProps = {
        label: "Closed",
        style: {
          backgroundColor: "#e0e0e0",
          color: "#616161",
          fontWeight: 600,
          font:'13px !important',
          borderRadius: 30,
          height:'25px !important'
        },
      };
      break;
    default:
      chipProps = {
        label: "Opened",
        style: {
          backgroundColor: "#e3f2fd",
          color: "#3f77a4",
          fontWeight: 600,
          font:'13px !important',
          borderRadius: 30,
          height:'25px !important'
        },
      };; // إذا كانت الحالة غير معروفة
  }

  return (
    <Chip
      label={chipProps.label}
      sx={{
        backgroundColor: chipProps.style.backgroundColor,
        color: chipProps.style.color,
        fontWeight: chipProps.style.fontWeight,
        borderRadius: chipProps.style.borderRadius,
        height: chipProps.style.height,
        font: chipProps.style.font,
        margin: 'auto',
      }}
    />
  );
};

export default StatusChip;
