import React, {useRef, useState} from "react";

interface ImageUploaderProps {
  placeholder?: string; // Optional string for placeholder
  defaultImage: string; // Required string for default image URL
  field: {
    onChange: (file: File | null) => void; // Function to update the form state
  };
}

const ImageUploaderView: React.FC<ImageUploaderProps> = ({
  defaultImage,
  field,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleSquareClick = () => {
    // Trigger the file input click when the square is clicked
    fileInputRef.current?.click();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      field.onChange(file); // Update the form state with the selected file
    }
  };

  const handleCancelImage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Prevent the click event from bubbling up
    setSelectedImage(null); // Clear the selected image
    field.onChange(null); // Update the form state with null
  };

  return (
    <div className="flex items-center">
      <input
        type="file"
        accept="image/*" // Accept image files
        ref={fileInputRef} // Attach the ref to the file input
        style={{display: "none"}} // Hide the file input
        onChange={handleImageChange} // Handle image change
      />
      <div
        onClick={handleSquareClick} // Open file dialog when square is clicked
        className="flex items-center justify-center w-20 h-20 border border-dashed border-gray-400 cursor-pointer relative"
      >
        {selectedImage ? (
          <>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Logo Preview"
              className="w-full h-full object-cover rounded"
            />
            <button
              type="button"
              onClick={handleCancelImage} // Call handleCancelImage on click
              className="absolute top-0 right-0 bg-secondary-brightYellow text-white rounded-full w-5 h-5 flex items-center justify-center"
            >
              &times; {/* X icon to cancel the image */}
            </button>
          </>
        ) : (
          <img
            src={defaultImage}
            alt="Default Logo"
            className="w-full h-full object-cover rounded"
          />
        )}
      </div>
    </div>
  );
};

export default ImageUploaderView;
