import React, { useEffect, useMemo, useState } from "react";
import OpportunityCard from "@/app/global-components/opportunity-card";
import { Input } from "@/app/global-components/input";
import NavPage from "../landingPage/presentation/nav/nav-page";
import { MultiSelect } from "@/app/global-components/multi-select";
import useOffer from "./components/hook/useTender";
import Footer from "../footer";

// Define the type for category options
interface CategoryOption {
  value: any; // Use specific types here, like string or number, if known
  label: any; // Use string if label is always a string
}

const TenderPage: React.FC = () => {
  const [visibleCards, setVisibleCards] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategories, setFilterCategories] = useState<string[]>([]);
  const [filteredOpportunities, setFilteredOpportunities] = useState<any[]>([]);
  const [opportunities, setOpportunities] = useState<any[]>([]);
  
  // Define Categoris with the correct type
  const [Categoris, setCategoris] = useState<CategoryOption[]>([]);
  
  const { getOffers, getcategories } = useOffer();

  useEffect(() => {
    const fetchCategoris = async () => {
      try {
        const Categories = await getcategories();
        if (Categories) {
          const uniqueCategories = Categories.data.filter((item: { name: any; }, index: any, self: any[]) =>
            index === self.findIndex((t) => t.name === item.name) // Only keep unique ids
          );
          const Cates: CategoryOption[] = uniqueCategories.map((item: { id: any; name: any; }) => ({
            value: item.id,
            label: item.name,
          }));
          setCategoris(Cates);
        } else {
          setCategoris([]);
        }
      } catch (error) {
        console.error("Error fetching Offers:", error);
      }
    };
    fetchCategoris();
  }, []);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const Offers = await getOffers();
        if (Offers) {
          console.log(Offers);
          setOpportunities(Offers.data);
        } else {
          setOpportunities([]);
        }
      } catch (error) {
        console.error("Error fetching Offers:", error);
      }
    };

    fetchOffers();
  }, []);

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight) {
      setVisibleCards((prevVisibleCards) => prevVisibleCards + 10);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = opportunities.filter(
      (opportunity) =>
        opportunity.title.toLowerCase().includes(lowercasedSearchTerm) &&
        (filterCategories.length === 0 ||
          opportunity.categories.some((category: string) => filterCategories.includes(category)))
    );
    setFilteredOpportunities(filtered);
  }, [searchTerm, filterCategories, opportunities]);
  
  return (
    <div className="flex flex-col min-h-screen" style={{ background: "#f8fafa !important" }}>
      <NavPage 
      isForm={0}
      />
      <div className="container mx-auto px-4 py-8" style={{ overflow: "auto", paddingTop: 130 }}>
        <div className="mb-6 flex flex-col items-center md:items-start text-right">
          <h2 className="text-3xl font-semibold tracking-tight mb-2 text-primary-dark">
            إليك قائمة بالفرص المتاحة لك !
          </h2>
        </div>
        <div className="flex flex-col md:flex-row md:items-center mb-6 gap-4">
          <Input
            type="text"
            placeholder="بحث..."
            value={searchTerm}
            className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs flex items-center md:text-base"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MultiSelect
            options={Categoris}
            onValueChange={(values: string[]) => setFilterCategories(values)}
            defaultValue={filterCategories}
            placeholder="اختر الفئة"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredOpportunities.slice(0, visibleCards).map((opportunity) => (
            <OpportunityCard key={opportunity.id} opportunity={opportunity} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TenderPage;
