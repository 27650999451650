// src/app/features/auth/components/company-registration-form-view.tsx
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import CompanySchema from "../../company-schema"; // Adjust the path as needed
import CompanyFieldView from "./company-field-view"; // Import the CompanyFieldView component
import CompanySubmitView from "./company-submit-view"; // Import the CompanySubmitView component
//import {useNavigate} from "react-router-dom";
import useRegisterCompany from "../hook/useRegisterCompany"; // Import the custom hook
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {useEffect, useState} from "react";
import regionsData from "@/app/features/company/data/regions_lite.json";
import citiesData from "@/app/features/company/data/cities_lite.json";
import {Separator} from "@/app/global-components/separator";
import { RootState } from "@/store/type";
import { useSelector } from "react-redux";
import { isNull } from "lodash";

interface Region {
  region_id: number;
  name_ar: string;
}

interface City {
  city_id: number;
  region_id: number;
  name_ar: string;
}

interface Option {
  value: string;
  label: string;
}

const CompanyFormView = () => {
  //const navigate = useNavigate();
  const {registerCompany, isLoading, error} = useRegisterCompany(); // Use the custom hook
  const [showStampedAuthorizationForm, setShowStampedAuthorizationForm] =
    useState<boolean>(false);
  const [regions, setRegions] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  const myCompany = useSelector((state: RootState) => state.auth.myCompany);
  const form = useForm<z.infer<typeof CompanySchema>>({
    resolver: zodResolver(CompanySchema),
    defaultValues: {
      id: null,
      nameAr: "",
      nameEn: "",
      website: "",
      address: "",
      region: "",
      city: "",
      registrationNumber: "",
      ownerType: "Owner", // Default value
      stampedAuthorizationForm: null,
      plaseOfIssue: "",
      turnover: 0,
      type: "",
      activities: "",
      categories: "",
      contactInfo: "",
      creationDate: "", // Change default value to null
      expireDate: "",
    },
  });
  useEffect(()=>{
    if(!isNull(myCompany)){
      form.reset({
        ...form.getValues(), // Behåll alla aktuella värden
        ...myCompany, // Överskriv med data från myCompany
        region: myCompany.region?.toString() || "", // Konvertera region till string
        city: myCompany.city?.toString() || "", // Konvertera stad till string
      });
    }
  },[myCompany])
  const {watch} = form;
  const ownerType = watch("ownerType");

  useEffect(() => {
    setRegions(
      regionsData.map((region: Region) => ({
        value: region.region_id.toString(), // Convert to string
        label: region.name_ar,
      }))
    );
  }, []);

  useEffect(() => {
    const selectedRegion = form.watch("region");
    if (selectedRegion) {
      const filteredCities = citiesData
        .filter((city: City) => city.region_id === parseInt(selectedRegion))
        .map((city: City) => ({
          value: city.city_id.toString(),
          label: city.name_ar,
        }));
      setCities(filteredCities);
    } else {
      setCities([]);
    }
  }, [form.watch("region")]);

  const onSubmit = async (values: z.infer<typeof CompanySchema>) => {
    try {
      const submissionData = {...values};
      if (submissionData.ownerType === "Owner") {
        submissionData.stampedAuthorizationForm = null;
      }
      console.log(submissionData);
      await registerCompany(submissionData);
      //navigate("/confirmation");
    } catch (err) {
      console.error("Error during registration:", err);
    }
  };

  useEffect(() => {
    // Update the state based on the ownerType value
    if (ownerType === "Authorized") {
      setShowStampedAuthorizationForm(true);
    } else {
      setShowStampedAuthorizationForm(false);
    }
  }, [ownerType]); // Only runs when ownerType changes

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="sm:flex sm:flex-row xs:gap-10">
          <section className="sm:w-1/2 w-full">
            <CompanyFieldView
              name="nameAr"
              label="اسم الشركة"
              placeholder="أختر اسم الشركة"
              formControl={form.control}
            />
          </section>
          <section className="sm:w-1/2 w-full">
            <CompanyFieldView
              name="nameEn"
              label="اسم الشركة (بالإنجليزية)"
              placeholder="أدخل اسم الشركة بالإنجليزية"
              formControl={form.control}
            />
          </section>
        </div>
        <CompanyFieldView
          name="website"
          label="الموقع الإلكتروني"
          placeholder="أدخل رابط الموقع الإلكتروني"
          formControl={form.control}
          inputType="text"
        />
        <Separator className="w-full bg-primary-gray h-[2px] " />
        <CompanyFieldView
          name="address"
          label="العنوان"
          placeholder="أدخل العنوان"
          formControl={form.control}
        />
        <div className="flex flex-col mt-2 sm:flex-row justify-between sm:items-center xs:gap-5 sm:gap-20 w-full">
          <section className=" sm:w-1/2 w-full flex flex-col">
            <CompanyFieldView
              name="region"
              label="اختر المنطقة"
              placeholder=""
              inputType="combobox"
              options={regions}
              formControl={form.control}
            />
          </section>
          <section className="flex flex-col sm:w-1/2 w-full">
            <CompanyFieldView
              name="city"
              label="اختر المدينة"
              placeholder="اختر المدينة"
              inputType="combobox"
              options={cities}
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px] " />
        <div className="sm:flex sm:flex-row xs:gap-5 justify-between items-center">
          <section className="sm:w-1/4 w-full">
            <CompanyFieldView
              name="registrationNumber"
              label="رقم السجل"
              placeholder="أدخل رقم السجل التجاري"
              formControl={form.control}
            />
          </section>
          <section className="sm:w-1/4 w-full">
            <CompanyFieldView
              name="turnover"
              label="حجم الأعمال"
              placeholder="أدخل حجم الأعمال"
              formControl={form.control}
            />
          </section>
          <section className="sm:w-1/4 w-full flex items-center">
            <CompanyFieldView
              name="ownerType"
              label="نوع المالك"
              placeholder="نوع المالك"
              inputType="radio"
              options={[
                {value: "Owner", label: "مالك"},
                {value: "Authorized", label: "مفوض"},
              ]}
              formControl={form.control}
            />
          </section>
          {showStampedAuthorizationForm && (
            <section className="sm:w-1/4 w-full">
              <CompanyFieldView
                name="stampedAuthorizationForm"
                label="معرف نموذج التفويض"
                placeholder="أدخل معرف نموذج التفويض"
                formControl={form.control}
              />
            </section>
          )}
        </div>
        <section className="w-full">
          <CompanyFieldView
            name="plaseOfIssue"
            label="مكان الإصدار"
            placeholder="أدخل مكان الإصدار"
            formControl={form.control}
          />
        </section>
        <div className="sm:flex sm:flex-row sm:gap-5  items-center">
          <section className="w-full">
            <CompanyFieldView
              name="creationDate"
              label=" تاريخ إنشاء السجل"
              placeholder="أدخل تاريخ إنشاء السجل"
              inputType="date"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <CompanyFieldView
              name="expireDate"
              label="تاريخ انتهاء السجل"
              placeholder="أدخل تاريخ انتهاء السجل"
              inputType="date"
              formControl={form.control}
            />
          </section>
        </div>
        <section className="w-full">
          <CompanyFieldView
            name="type"
            label="النوع"
            placeholder="أدخل النوع"
            formControl={form.control}
          />
        </section>
        <Separator className="w-full bg-primary-gray h-[2px] " />
        <div className="sm:flex sm:flex-row items-center  xs:gap-10">
          <section className="w-full">
            <CompanyFieldView
              name="categories"
              label="فئات"
              placeholder="أدخل الفئات"
              inputType="textarea"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <CompanyFieldView
              name="activities"
              label="الأنشطة"
              placeholder="أدخل الأنشطة"
              inputType="textarea"
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px] " />
        <div className="sm:flex sm:flex-row items-center  xs:gap-10">
          <section className="w-4/5">
            <CompanyFieldView
              name="contactInfo"
              label="معلومات الاتصال"
              placeholder="أدخل بريد إلكتروني للتواصل"
              formControl={form.control}
            />
          </section>
          <section className="w-1/5">
            <CompanyFieldView
              name="logoMedia"
              label="معرف وسائط الشعار"
              placeholder=" اختر صورة الشعار"
              inputType="file"
              formControl={form.control}
            />
          </section>
        </div>
        {error && (
          <p className="text-secondary-brightYellow  text-sm mt-2">{error}</p>
        )}
        <CompanySubmitView isLoading={isLoading} /> {/* Pass loading state */}
      </form>
    </Form>
  );
};

export default CompanyFormView;
