import { isUndefined } from "lodash";
import React from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectTagProps {
  options: Option[]; // Keep it required for clarity
  placeholder?: string;
  onValueChange: (value: string) => void; // Call back for value changes
  defaultValue?: string; // Optional default value
  className?: string; // Optional className for styling
  value?: string; // Optional className for styling
}

const SelectTag: React.FC<SelectTagProps> = ({
  options = [], // Provide a default empty array
  onValueChange,
  defaultValue,
  placeholder,
  className,
  value
}) => {
  return (
    <select
      className={`flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring ${className}`}
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => onValueChange(e.target.value)} // Handle value change
    >
      {placeholder && <option value="" disabled>{placeholder}</option>} {/* Render placeholder as disabled option */}
      {
      isUndefined(options)?"":
      options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export { SelectTag };
