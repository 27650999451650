import React from 'react';
import RGL, { WidthProvider,  Layout } from 'react-grid-layout'; // Import the correct types
import WelComeBox from './Wedgits/welcome-box';
import TotalAvailableOffer from './Wedgits/total-available-offer';
import TotalOpenedOffer from './Wedgits/total-opened-offer';
import TotalClosedOffer from './Wedgits/total-closed-offer';
//import TotalOfferpermonth from './Wedgits/total-offer-monthly';
import TotalOfferperStatus from './Wedgits/total-offer-status';
//import ListOfOpenedOffers from './Wedgits/list-opened-offers';
//import ListOfClosedOffers from './Wedgits/list-closed-offers';
import TrenderQuatationsList from './Wedgits/treder-quatations-list';

const ReactGridLayout = WidthProvider(RGL);

interface ExtraLargeGridProps {}

// Define the layout array as an array of objects matching the Layout type
const layout: Layout[] = [
  { i: '1', x: 0, y: 0, w: 3, h: 5, static: true }, 
  { i: '2', x: 3, y: 0, w: 3, h: 5, static: true },
  { i: '3', x: 6, y: 0, w: 3, h: 5, static: true },
  { i: '4', x: 9, y: 0, w: 3, h: 5, static: true },
  { i: '5', x: 0, y: 5, w: 3, h: 15, static: true },
  { i: '6', x: 6, y: 5, w: 9, h: 25, static: true },
];

const ExtraLargeGrid: React.FC<ExtraLargeGridProps> = () => {
  return (
    <div className="module-list-scroll scrollbar actionSidebar" style={{ direction: 'ltr', padding: "130px 20px" }}>
      <ReactGridLayout
        className="layout"
        layout={layout}
        rowHeight={20}
        isDraggable={false}
        isResizable={false}
        cols={12} // Ensure you provide the number of columns
      >
        <div style={{ backgroundColor: 'white', zIndex: 3 }} key="1">
          <span className="text"><TotalOpenedOffer /></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3 }} key="2">
          <span className="text"><TotalClosedOffer /></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3 }} key="3">
          <span className="text"><TotalAvailableOffer /></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3 }} key="4">
          <span className="text"><WelComeBox /></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3 }} key="5">
          <span className="text"><TotalOfferperStatus /></span>
        </div>
        <div style={{ backgroundColor: 'white', zIndex: 3 }} key="6">
          <span className="text"><TrenderQuatationsList /></span>
        </div>
      </ReactGridLayout>
    </div>
  );
};

export default ExtraLargeGrid;
