import ResetPasswordSchema from "../../../@core/schema/reset-password-schema";
import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {Input} from "@/app/global-components/input";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import useResetPassword from "../hook/useResetPassword";

interface ResetPasswordFormFieldViewProps {
  name: FieldPath<z.infer<typeof ResetPasswordSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof ResetPasswordSchema>>;
}

const ResetPasswordFormFieldView: React.FC<ResetPasswordFormFieldViewProps> = ({
  name,
  label,
  placeholder,
  inputType,
  formControl,
}) => {
  const {resendResetCode, isLoading} = useResetPassword();

  const handleResendCode = async () => {
    try {
      await resendResetCode();
      // You can add a success message here if needed
    } catch (err) {
      // Error is handled in the hook, but you can add additional handling here if needed
    }
  };

  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem>
          {name === "code" ? (
            <div className="flex justify-between items-center mb-2">
              <FormLabel className="float-right">{label}</FormLabel>
              <p className="text-sm text-textcolor-mediumGray">
                <span
                  className="underline text-textcolor-black cursor-pointer"
                  onClick={handleResendCode}
                  style={{pointerEvents: isLoading ? "none" : "auto"}}
                >
                  {isLoading ? "جاري إعادة الإرسال..." : "أعد إرسال الرمز؟"}
                </span>
              </p>
            </div>
          ) : (
            <FormLabel className="float-right mb-2">{label}</FormLabel>
          )}
          <FormControl>
            <Input
              className="focus-visible:ring-secondary-brightYellow"
              placeholder={placeholder}
              type={inputType || "text"}
              {...field}
            />
          </FormControl>
          <FormMessage className="text-secondary-brightYellow" />
        </FormItem>
      )}
    />
  );
};

export default ResetPasswordFormFieldView;
