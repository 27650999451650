import {z} from "zod";

const OfferTypeEnum = z.enum(["Tiny", "Huge"]);
const OfferStatusEnum = z.enum(["Opened", "Closed", "Accepted", "Finished"]);


export const TenderSchema = z
  .object({
    id: z.string().nullable(),
    title: z.string().min(1, "العنوان مطلوب"),
    minValue: z
    .preprocess(
      (val) => (val === "" ? undefined : Number(val)),
      z
        .number({
          invalid_type_error: "يجب إدخال رقم صحيح للقيمة الدنيا",
        })
        .positive("يجب أن تكون القيمة الدنيا أكبر من الصفر")
        .optional() // minValue is now optional
    ),
    value: z.preprocess(
      (val) => (val === "" ? undefined : Number(val)),
      z
        .number({
          required_error: "القيمة مطلوبة",
          invalid_type_error: "يجب إدخال رقم صحيح للقيمة",
        })
        .positive("يجب أن تكون القيمة أكبر من الصفر")
    ),
    companyId: z.string(),
    categoriesIds: z.array(z.string()).optional(), 
    fileName: z.string().optional(),
    fileDescription: z.string().optional(),
    fileId: z.string().nullable(),    
    attachmentName: z.string().optional(),
    attachmentDescription: z.string().optional(),
    attachmentRequired:z.boolean().optional(),
    attachmentId: z.string().nullable(),
    endDate: z.string().min(1, "آخر موعد لتقديم العروض مطلوب"),
    deliverDate: z.string().min(1, "تاريخ فتح العروض مطلوب"),
    type: OfferTypeEnum,
    status: OfferStatusEnum.nullable(),
    region: z.string().min(1, "المنطقة مطلوبة"),
    city: z.string().min(1, "المدينة مطلوبة"),
    receiveDocumentsType: z
      .enum(["Both", "OneByOne"])
      .optional()
      .default("Both"),
    Paylater: z
      .enum(["Yes", "No"])
      .optional()
      .default("No"),
    contactInfo: z.string().email("عنوان البريد الإلكتروني غير صالح").optional(),
    products: z.array(
      z.object({
        item: z.string().min(1, "اسم المنتج مطلوب"),
        quantity: z.preprocess(
          (val) => (val === "" ? undefined : Number(val)),
          z
            .number({
              required_error: "الكمية مطلوبة",
              invalid_type_error: "يجب إدخال رقم صحيح للكمية",
            })
            .positive("يجب أن تكون الكمية أكبر من الصفر")
        ),
        attachment: z
          .instanceof(File)
          .nullable()
          .optional(),
      })
    ).optional(),
  })
  .refine(
    (data) => {
      const endDate = new Date(data.endDate);
      const deliverDate = new Date(data.deliverDate);
      return deliverDate > endDate;
    },
    {
      message: "تاريخ فتح العروض يجب أن يكون بعد آخر موعد لتقديم العروض",
      path: ["deliverDate"],
    }
  )
  .refine(
    (data) => {
      // Check if both values are defined before comparing
      return data.value !== undefined && data.minValue !== undefined
        ? data.value > data.minValue
        : true;
    },
    {
      message: "يجب أن تكون القيمة أكبر من القيمة الدنيا",
      path: ["value"],
    }
  );

export type TenderFormData = z.infer<typeof TenderSchema>;
