// src/app/features/auth/hooks/useRegisterCompany.ts

import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import CompanySchema from "../../company-schema"; // Adjust the path as needed
import api from "@/store/api";
import { getData } from "@/lib/utils";
import { useDispatch } from "react-redux";
import { getMyCompany } from "@/store/authSlice";

const useRegisterCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const registerCompany = async (values: z.infer<typeof CompanySchema>) => {
    setIsLoading(true);
    setError(null);
    
    try {
      let Token = getData("tokens")?.accessToken;
      const response = await api.post("/web/companies/upsert", values, {
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      }); // Adjust the endpoint as needed
      setIsLoading(false);
      return response.data; // Adjust this based on your API response
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while registering the company"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };
  const getCompanyMine = async ()=>{
    
    try {
      let Token = getData("tokens")?.accessToken;
      const response = await api.get("/web/companies/get/my", {
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      }); // Adjust the endpoint as needed
      setIsLoading(false);
      dispatch(getMyCompany(response.data.data)); 
      return response.data; // Adjust this based on your API response
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while registering the company"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  }
  const getCompany = async (id: string)=>{
    
    try {
      const response = await api.get("/web/companies/get", {params: { id}}); // Adjust the endpoint as needed
      setIsLoading(false);
      dispatch(getMyCompany(response.data.data)); 
      return response.data; // Adjust this based on your API response
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while registering the company"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  }
  return {registerCompany,getCompanyMine,getCompany, isLoading, error};
};

export default useRegisterCompany;
