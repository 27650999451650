import LoginSchema from "../../../../@core/schema/login-schema";
import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {Input} from "@/app/global-components/input";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import {Link} from "react-router-dom";

interface LoginFormFieldProps {
  name: FieldPath<z.infer<typeof LoginSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof LoginSchema>>;
}

const LoginFieldView: React.FC<LoginFormFieldProps> = ({
  name,
  label,
  placeholder,
  inputType,
  formControl,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem className="mb-4">
          {name === "password" ? (
            <div className="flex flex-col xs:flex-row justify-between items-center mb-2">
              <FormLabel className="mb-2 sm:mb-0">{label}</FormLabel>
              <p className="text-sm text-textcolor-mediumGray">
                <span className="underline text-textcolor-black cursor-pointer">
                  <Link to="/forgotpassword"> نسيت كلمة المرور؟</Link>
                </span>
              </p>
            </div>
          ) : (
            <FormLabel className="mb-2">{label}</FormLabel>
          )}

          <FormControl>
            <Input
              className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-sm"
              placeholder={placeholder}
              type={inputType || "text"}
              {...field}
            />
          </FormControl>
          <FormMessage className="text-secondary-brightYellow text-xs" />
        </FormItem>
      )}
    />
  );
};

export default LoginFieldView;
