import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import ResetPasswordSchema from "@/app/features/auth/@core/schema/reset-password-schema";
import api from "@/store/api";

const useResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const resetPassword = async (values: z.infer<typeof ResetPasswordSchema>) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post("/api/reset-password", values);
      setIsLoading(false);
      return response.data; // Adjust this based on your API response
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while resetting the password"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  const resendResetCode = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const email = sessionStorage.getItem("emailForgotPassword");
      const response = await api.post("/api/resend-reset-code", {email});
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while resending the code"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  return {resetPassword, isLoading, resendResetCode, error};
};

export default useResetPassword;
