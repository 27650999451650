import ResetPasswordSchema from "../../../@core/schema/reset-password-schema";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import ResetPasswordFormFieldView from "./reset-password-form-field-view";
import ResetPasswordSubmitView from "./reset-password-submit-view";
import useResetPassword from "@/app/features/auth/presentation/reset-password/hook/useResetPassword"; // Import the new hook
import {useNavigate} from "react-router-dom";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const {resetPassword, isLoading, error} = useResetPassword(); // Use the new hook

  const form = useForm<z.infer<typeof ResetPasswordSchema>>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: {
      newPassword: "",
      code: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof ResetPasswordSchema>) => {
    try {
      await resetPassword(values);
      // Optionally, navigate to a success page or show a success message
      navigate("/login"); // Redirect to login page after successful reset
    } catch (err) {
      // Error is already handled in the hook
      console.error(err);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <ResetPasswordFormFieldView
          name="code"
          label="الرمز"
          placeholder="أدخل الرمز"
          inputType="text"
          formControl={form.control}
        />
        <ResetPasswordFormFieldView
          name="newPassword"
          label="كلمة المرور الجديدة"
          placeholder="أدخل كلمة المرور الجديدة"
          inputType="password"
          formControl={form.control}
        />
        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
        <ResetPasswordSubmitView isLoading={isLoading} />
      </form>
    </Form>
  );
};

export default ResetPasswordForm;
