import  { useState } from "react";

const FAQItem = ({ question, answer }: { question: string; answer: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item border-b border-gray-300 py-4">
      <h3
        className="cursor-pointer font-bold text-xl"
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
      </h3>
      {isOpen && <p className="text-gray-600 mt-2">{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "ما هي فوائد استخدام ميدكس؟",
      answer:
        "توفير الوقت والجهد، مقارنة الأسعار، الحصول على حلول تمويلية، وضمان جودة المنتجات من خلال التعامل مع موردين معتمدين.",
    },
    {
      question: "من يستفيد من ميدكس؟",
      answer:
        "المستشفيات، المراكز الطبية، الصيدليات، موردي المستلزمات الطبية، وأي جهة تحتاج إلى شراء أو بيع مستلزمات طبية.",
    },
    {
      question: "كيف أقدم عرض سعر على مناقصة؟",
      answer:
        "قم بتسجيل الدخول إلى حسابك، اختر المناقصة التي ترغب في التقديم عليها، واملأ بيانات العرض وقم بتقديمه عبر المنصة.",
    },
    {
      question: "كيف يتم تأكيد جودة المنتجات؟",
      answer:
        "نتأكد من جودة المنتجات من خلال التعامل مع موردين موثوقين واعتماد معايير الجودة الدولية لضمان أن جميع المنتجات تلبي متطلبات الصحة والسلامة.",
    },
  ];

  return (
    <section className="faq-section py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-extrabold mb-8 text-center">أسئلة شائعة حول منصة ميدكس</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
