import TenderFormView from "./components/view/tender-form-view"; // Update the path as needed
import NavPage from "../landingPage/presentation/nav/nav-page";
import Footer from "../footer";
export default function TenderPage() {
  return (
    <div className="flex flex-col min-h-screen" style={{background: "#f8fafa !important"}}>
    <NavPage 
      isForm={0}
      />
    <div className="xs:flex xs:flex-col xs:justify-center items-center sm:shadow-lg" style={{paddingTop:130}}>
      <main className="flex min-h-screen items-center justify-center w-full sm:w-3/5 flex-col">
        <section className="w-full max-w-xs xs:justify-center bg-primary-white p-5 rounded-md xs:max-w-5xl py-6">
          <div className="flex flex-col">
            <h1 className="sm:text-2xl text-base font-semibold">
              إنشاء عرض جديد
            </h1>
            <p className="mb-4 text-secondary-brightYellow text-sm sm:text-base">
              قم بملء النموذج لإنشاء عرض جديد
            </p>
          </div>
          <TenderFormView />
        </section>
      </main>
    </div>
    <Footer />
    </div>
  );
}
