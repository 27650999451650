// src/components/ProtectedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from '@/store/type'; // Adjust the path as necessary

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const authUser = useSelector((state: RootState) => state.auth.authUser);

  // Check if authUser is null (not authenticated)
  if (!authUser) {
    // Redirect to login page
    return <Navigate to="/login" replace />;
  }

  // Render the children components if authenticated
  return children;
};

export default ProtectedRoute;
