// src/app/features/auth/@core/schema/change-password-schema.ts
import {z} from "zod";

const ChangePasswordSchema = z
  .object({
    oldPassword: z
      .string()
      .min(8, {message: "كلمة المرور القديمة يجب أن تكون 8 أحرف على الأقل"}),
    newPassword: z
      .string()
      .min(8, {message: "كلمة المرور الجديدة يجب أن تكون 8 أحرف على الأقل"}),
    confirmPassword: z
      .string()
      .min(8, {message: "تأكيد كلمة المرور يجب أن يكون 8 أحرف على الأقل"}),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "كلمات المرور غير متطابقة",
    path: ["confirmPassword"],
  });

export default ChangePasswordSchema;
