// src/app/features/auth/components/register-submit-view.tsx

import {Button} from "@/app/global-components/button"; // Ensure this path is correct
import {ImSpinner2} from "react-icons/im";

interface ProfileSubmitViewProps {
  isLoading: boolean;
  isNewUser: boolean;
}

const ProfileSubmitView: React.FC<ProfileSubmitViewProps> = ({isLoading,isNewUser}) => {
  return (
    <div>
      <Button
        className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black w-full py-2 px-4 text-sm"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <span className="ml-2 animate-spin">
              <ImSpinner2 />
            </span>
            جاري الإرسال...
          </>
        ) : (
          isNewUser===true? "تسجيل":"حفظ"
        )}
      </Button>
    </div>
  );
};
export default ProfileSubmitView;
