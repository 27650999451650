// src/app/features/auth/components/change-password-form-field-view.tsx
import ChangePasswordSchema from "../../../@core/schema/chagne-password-schema";
import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {Input} from "@/app/global-components/input";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";

interface ChangePasswordFormFieldViewProps {
  name: FieldPath<z.infer<typeof ChangePasswordSchema>>;
  label: string;
  placeholder: string;
  inputType?: string;
  formControl: Control<z.infer<typeof ChangePasswordSchema>>;
}

const ChangePasswordFormFieldView: React.FC<
  ChangePasswordFormFieldViewProps
> = ({name, label, placeholder, inputType, formControl}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem>
          <FormLabel className="float-right mb-2">{label}</FormLabel>
          <FormControl>
            <Input
              className="focus-visible:ring-secondary-brightYellow"
              placeholder={placeholder}
              type={inputType || "password"}
              {...field}
            />
          </FormControl>
          <FormMessage className="text-secondary-brightYellow" />
        </FormItem>
      )}
    />
  );
};

export default ChangePasswordFormFieldView;
