// src/app/features/offer/components/offer-submit-view.tsx

import React from "react";
import {Button} from "@/app/global-components/button"; // Ensure this path is correct
import {ImSpinner2} from "react-icons/im";

interface TenderSubmitViewProps {
  isLoading: boolean;
}

const OfferSubmitView: React.FC<TenderSubmitViewProps> = ({isLoading}) => {
  return (
    <div>
      <Button
        className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black w-full py-2 px-4 text-sm"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <span className="ml-2 animate-spin">
              <ImSpinner2 />
            </span>
            جاري إرسال العرض...
          </>
        ) : (
          "إرسال العرض"
        )}
      </Button>
    </div>
  );
};

export default OfferSubmitView;
