import {Link} from "react-router-dom";
import RegisterFormView from "./components/views/register-form-view";

export default function RegisterPage() {
  return (
    <main className="flex min-h-screen flex-col items-center justify-center px-4 sm:px-6">
      <section className="w-full max-w-xs bg-primary-white p-5 shadow-xl rounded-md sm:max-w-lg">
        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold  sm:text-3xl">التسجيل</h1>
          <p className="mb-4 text-textcolor-mediumGray text-sm sm:text-base">
            لديك حساب؟
            <Link
              to="/login"
              className="underline text-secondary-brightYellow underline-offset-4"
            >
              {" "}
              تسجيل الدخول
            </Link>
          </p>
        </div>
        <RegisterFormView />
      </section>
    </main>
  );
}
