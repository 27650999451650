// src/app/features/auth/components/register-submit-view.tsx

import {Button} from "@/app/global-components/button"; // Ensure this path is correct
import {ImSpinner2} from "react-icons/im";

interface RegisterSubmitViewProps {
  isLoading: boolean;
}

const CompanySubmitView: React.FC<RegisterSubmitViewProps> = ({isLoading}) => {
  return (
    <div className="flex justify-center">
      <Button
        className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black w-full py-2 px-4 text-sm sm:text-base"
        type="submit"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <span className="mr-2 animate-spin">
              <ImSpinner2 />
            </span>
          </>
        ) : (
          "حفظ"
        )}
      </Button>
    </div>
  );
};

export default CompanySubmitView;
