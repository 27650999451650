// OfferFieldView.tsx
import React from "react";
import {TenderSchema} from "../../tender-schema";
import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {RadioGroup, RadioGroupItem} from "@/app/global-components/radio-group";
import ArabicDatePicker from "@/app/global-components/arbic-date-picker";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import {Input} from "@/app/global-components/input";
import {ComboboxView} from "@/app/global-components/combobox-view";
import {Textarea} from "@/app/global-components/textarea";
import {MultiSelect} from "@/app/global-components/multi-select"; //
import { Clear } from "@mui/icons-material";


interface TenderFieldViewProps {
  name: FieldPath<z.infer<typeof TenderSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?:
    | "text"
    | "radio"
    | "date"
    | "combobox"
    | "number"
    | "textarea"
    | "multiselect"
    | "file";
  options?: {value: string; label: string}[];
  formControl: Control<z.infer<typeof TenderSchema>>;
}

const TenderFieldView: React.FC<TenderFieldViewProps> = ({
  name,
  label,
  placeholder,
  inputType,
  options,
  formControl,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem className="mb-4">
          <FormLabel className="mb-2 text-xs lg:text-base">{label}</FormLabel>
          <FormControl>
            {inputType === "radio" && options ? (
              <RadioGroup onValueChange={field.onChange}>
                <div className="flex flex-row gap-3  items-center justify-end">
                  {options.map((option) => (
                    <label
                      key={option.value}
                      className="flex items-center mr-3 text-xs md:text-base mb-2"
                    >
                      <RadioGroupItem
                        checked={field.value === option.value}
                        value={option.value}
                        className="mr-2"
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
              </RadioGroup>
            ) : inputType === "multiselect" && options ? (
              <MultiSelect
                options={options}
                onValueChange={(value) => field.onChange(value)}
                defaultValue={field.value as string[]}
                placeholder={placeholder}
              />
            ) : inputType === "date" ? (
              <div className="flex items-center">
                <ArabicDatePicker
                  date={
                    field.value && typeof field.value === "string"
                      ? new Date(field.value)
                      : null
                  }
                  setDate={(date) => {
                    if (date) {
                      const localDateString = date.toLocaleDateString("en-CA");
                      field.onChange(localDateString);
                    } else {
                      field.onChange(null);
                    }
                  }}
                />
              </div>
            ) : inputType === "combobox" && options ? (
              <ComboboxView
                placeholder={placeholder}
                options={options}
                value={field.value as string}
                onChange={(value) => field.onChange(value)}
              />
            ) : inputType === "textarea" ? (
              <Textarea
                {...field}
                className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs md:text-base"
                placeholder={placeholder}
                value={typeof field.value === "string" ? field.value : ""}
              />
            ) : inputType === "file" ? (
              <div className="relative">
                <Input
                  type="text"
                  readOnly
                  //value={typeof field.value === "string" ? field.value : ""}
                  value={field.value instanceof File ? field.value.name : ""}
                  placeholder="اختر ملفًا (صور، PDF، Word)"
                  className="cursor-pointer focus-visible:ring-secondary-brightYellow"
                  onClick={() =>
                    document.getElementById(`file-upload-${name}`)?.click()
                  }
                />
                <Input
                  id={`file-upload-${name}`}
                  type="file"
                  accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx"
                  className="hidden focus-visible:ring-secondary-brightYellow"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const fileType = file.type;
                      const validTypes = [
                        "image/jpeg",
                        "image/png",
                        "image/gif",
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ];
                      if (validTypes.includes(fileType)) {
                        field.onChange(file);
                      } else {
                        alert(
                          "الرجاء اختيار ملف صورة أو PDF أو مستند Word فقط."
                        );
                        field.onChange(null); // Clear the field value
                      }
                    } else {
                      // This will trigger when the user cancels the file selection
                      field.onChange(null); // Clear the field value
                    }
                    e.target.value = ""; // Always clear the file input
                  }}
                />
                 {field.value && (
                  <Clear
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer text-red-500"
                  onClick={() =>  field.onChange(null)}
                  />
                )}
                {!field.value && (
                  <div className="focus-visible:ring-secondary-brightYellow absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"></div>
                )}
              </div>
            ) : (
              <Input
                {...field}
                className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs flex items-center md:text-base"
                type={inputType || "text"}
                value={
                  typeof field.value === "string" ||
                  typeof field.value === "number"
                    ? field.value
                    : ""
                }
                onChange={(e) => {
                  const value =
                    inputType === "number"
                      ? parseFloat(e.target.value)
                      : e.target.value;
                  field.onChange(value);
                }}
              />
            )}
          </FormControl>
          <FormMessage className="text-secondary-brightYellow text-xs sm:text-sm" />
        </FormItem>
      )}
    />
  );
};

export default TenderFieldView;
