import { z } from "zod";

export const tenderQuotationSchema = z.object({
  id: z.string().nullable(), // ID can be optional if not provided initially
  tenderId: z.string().nonempty("Tender ID is required"),
  companyId: z.string().nonempty("Company ID is required"),
  userId: z.string().nonempty("User ID is required"),
  //value: z.number().min(1, "Value must be greater than 0"),
 //description: z.string().nonempty("Description is required"),
  products: z.array(
    z.object({
      unitName: z.string().min(1, "اسم المنتج مطلوب"),
      quantity: z.preprocess(
        (val) => (val === "" ? undefined : Number(val)),
        z
          .number({
            required_error: "الكمية مطلوبة",
            invalid_type_error: "يجب إدخال رقم صحيح للكمية",
          })
          .positive("يجب أن تكون الكمية أكبر من الصفر")
      ),
      unitPrice: z.preprocess(
        (val) => (val === "" ? undefined : Number(val)),
        z
          .number({
            required_error: "الكمية مطلوبة",
            invalid_type_error: "يجب إدخال رقم صحيح للكمية",
          })
          .positive("يجب أن تكون الكمية أكبر من الصفر")
      ),
      attachment: z
        .instanceof(File)
        .nullable()
        .optional(),
    })
  ).optional(),
  deliverDays: z.number().min(1, "Value must be greater than 0"),
  DeadLineDate: z.string().min(1, "Value must be greater than 0"),
  status:z
  .enum(["Pending", "Accepted"])
  .default("Pending"),
  paymentMethod:z
  .enum(["1", "2"])
  .default("1"),
  contactMethod:z
  .enum(["1", "2"])
  .default("1"),
  //acceptTerms:z.boolean().default(false)
});

export type TenderQuotation = z.infer<typeof tenderQuotationSchema>;
