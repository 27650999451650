import ResetPasswordForm from "./components/reset-password-form-view";

export default function ResetPasswordPage() {
  const emailForgetPassword = sessionStorage.getItem("emailForgotPassword");

  return (
    <main className="flex min-h-screen flex-col items-center justify-center p-4 sm:p-6">
      <section className="w-full max-w-md bg-primary-white p-5 shadow-xl rounded-md sm:max-w-lg">
        <div className="flex flex-col mb-4">
          <h1 className="text-2xl font-semibold">إعادة تعيين كلمة المرور</h1>
          {emailForgetPassword && (
            <p className="text-sm text-textcolor-mediumGray">
              ستتلقى رمزًا على البريد الإلكتروني{" "}
              <span className="text-secondary-brightYellow font-semibold">
                {emailForgetPassword}
              </span>
            </p>
          )}
        </div>
        <ResetPasswordForm />
      </section>
    </main>
  );
}
