// OfferFormView.tsx
import React, {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {TenderSchema, TenderFormData} from "../../tender-schema";
import TenderFieldView from "./tender-field-view";
import {useNavigate} from "react-router-dom";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {Separator} from "@/app/global-components/separator";
import useOffer from "../hook/useTender"; // Assuming you have a custom hook for offer operations
import regionsData from "@/app/features/company/data/regions_lite.json";
import citiesData from "@/app/features/company/data/cities_lite.json";
import OfferSubmitView from "./tender-submit-view";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { isEmpty } from "lodash";
import { Alert } from "@mui/material";
interface Region {
  region_id: number;
  name_ar: string;
}

interface City {
  city_id: number;
  region_id: number;
  name_ar: string;
}

interface Option {
  value: string;
  label: string;
}
interface Product {
  item: string;
  quantity: number;
  attachment: File | null;
}
interface CategoryOption {
  value: any; // Use specific types here, like string or number, if known
  label: any; // Use string if label is always a string
}
const TenderFormView: React.FC = () => {
  const navigate = useNavigate();
  const {createOffer, getcategories,isLoading, error} = useOffer(); // Custom hook for offer operations
  const [regions, setRegions] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  const [products, setProducts] = useState<Product[]>([{ item: "", quantity: 1, attachment: null }]);
  const [Categoris, setCategoris] = useState<CategoryOption[]>([]);
  const myCompany = useSelector((state: RootState) => state.auth.myCompany);
  const form = useForm<z.infer<typeof TenderSchema>>({
    resolver: zodResolver(TenderSchema),
    defaultValues: {
      id: null,
      title: "",
      minValue: 1,
      value: 0,
      endDate: "",
      deliverDate: "",
      type: "Tiny",
      status: null,
      region: "",
      city: "",
      receiveDocumentsType: "Both",
      Paylater: "No",
      contactInfo: "",
      categoriesIds: [], // Empty array as default for categories
      fileName: "",
      fileDescription: "",
      fileId: null,
      attachmentRequired: false,
      attachmentName: "",
      attachmentDescription: "",
      attachmentId: null,
      products:[],
      companyId:myCompany?.id
    },
  });

  useEffect(() => {
    setRegions(
      regionsData.map((region: Region) => ({
        value: region.region_id.toString(),
        label: region.name_ar,
      }))
    );
  }, []);

  useEffect(() => {
    const selectedRegion = form.watch("region");
    if (selectedRegion) {
      const filteredCities = citiesData
        .filter((city: City) => city.region_id === parseInt(selectedRegion))
        .map((city: City) => ({
          value: city.city_id.toString(),
          label: city.name_ar,
        }));
      setCities(filteredCities);
    } else {
      setCities([]);
    }
  }, [form.watch("region")]);

  useEffect(() => {
    form.setValue("companyId", myCompany.id)
  },[myCompany])

  const addProduct = () => {
    const newProduct = { item: "", quantity: 1, attachment: null };
    const currentProducts = form.getValues("products") as Product[];
    const updatedProducts = [...currentProducts, newProduct];
    setProducts(updatedProducts); // Update local state

    // Update form state
    form.setValue("products", updatedProducts);
  };

  const removeProduct = (index: number) => {
    const currentProducts = form.getValues("products") as Product[];
    const newProducts = currentProducts.filter((_, i) => i !== index);
    setProducts(newProducts);
    form.setValue("products", newProducts);
  };
  const onSubmit = async (values: TenderFormData) => {
    try {
      await createOffer(values);
      navigate("/tenders"); // Navigate to offers list or confirmation page
    } catch (err) {
      console.error("Error creating offer:", err);
    }
  };
  useEffect(() => {
    const fetchCategoris = async () => {
      try {
        const Categories = await getcategories();
        if (Categories) {
          const uniqueCategories = Categories.data.filter((item: { name: any; }, index: any, self: any[]) =>
            index === self.findIndex((t) => t.name === item.name) // Only keep unique ids
          );
          const Cates: CategoryOption[] = uniqueCategories.map((item: { id: any; name: any; }) => ({
            value: item.id,
            label: item.name,
          }));
          setCategoris(Cates);
        } else {
          setCategoris([]);
        }
      } catch (error) {
        console.error("Error fetching Offers:", error);
      }
    };
    fetchCategoris();
  }, []);
  console.log(form.formState.errors);
  console.log(myCompany);
  console.log(myCompany.id);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex flex-col gap-5 w-full md:flex-row space-x-4">
          <section className="w-full">
            <TenderFieldView
              name="title"
              label="عنوان العرض"
              placeholder="أدخل عنوان العرض"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <TenderFieldView
              name="categoriesIds"
              label="الفئات"
              placeholder="اختر الفئات"
              inputType="multiselect"
              options={Categoris}
              formControl={form.control}
            />
          </section>
        </div>
        <div className="flex flex-col gap-5 w-full md:flex-row space-x-4">
          <section className="w-full" style={{display:"none"}}>
            <TenderFieldView
              name="minValue"
              label="القيمة الدنيا"
              placeholder="أدخل القيمة الدنيا"
              inputType="number"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <TenderFieldView
              name="value"
              label="القيمة"
              placeholder="أدخل القيمة"
              inputType="number"
              formControl={form.control}
            />
          </section>
        </div>
        {/*  */}
        <section className="w-full md:justify-center" style={{display:"none"}}>
          <TenderFieldView
            name="type"
            label="نوع العرض"
            placeholder="اختر نوع العرض"
            inputType="radio"
            options={[
              {value: "Tiny", label: "صغير"},
              {value: "Huge", label: "كبير"},
            ]}
            formControl={form.control}
          />
        </section>
      
        <Separator className="w-full bg-primary-gray h-[2px]" />

        {/* Product Table */}
        <div className="flex flex-col gap-5 w-full">
          <h3 className="text-lg font-semibold">البنود</h3>
          <table className="min-w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2">البند</th>
                <th className="border border-gray-300 p-2">الكمية</th>
                <th className="border border-gray-300 p-2">المرفق</th>
                <th className="border border-gray-300 p-2"></th>
              </tr>
            </thead>
            <tbody>
              {products.map(({}, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2">
                    <TenderFieldView
                      name={`products.${index}.item`}
                      placeholder="أدخل اسم البند"
                      label=""
                      inputType="text"
                      formControl={form.control}
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <TenderFieldView
                      name={`products.${index}.quantity`}
                      label=""
                      placeholder="أدخل الكمية"
                      inputType="number"
                      formControl={form.control}
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                    <TenderFieldView
                      name={`products.${index}.attachment`}
                      placeholder="اختر ملفًا"
                      label=""
                      inputType="file"
                      formControl={form.control}
                    />
                  </td>
                  <td className="border border-gray-300 p-2">
                  <button type="button" onClick={() => removeProduct(index)} className="text-red-500">
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button type="button" onClick={addProduct} className="mt-4 p-2 bg-blue-500 text-white rounded">
            إضافة بند 
          </button>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px] " />

        <div className="flex flex-col gap-5 w-full md:flex-row space-x-4">
          <section className="w-full">
            <TenderFieldView
              name="fileName"
              label="اسم الملف"
              placeholder="أدخل اسم الملف"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <TenderFieldView
              name="fileDescription"
              label="وصف الملف"
              placeholder="أدخل وصف الملف"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <TenderFieldView
              name="fileId"
              label="الملف"
              placeholder="اختر ملفًا"
              inputType="file"
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px] " />

        <div className="flex flex-col gap-5 w-full md:flex-row space-x-4">
          <section className="w-full">
            <TenderFieldView
              name="endDate"
              label="آخر موعد لتقديم العروض"
              placeholder="حدد آخر موعد لتقديم العروض"
              inputType="date"
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <TenderFieldView
              name="deliverDate"
              label="تاريخ فتح العروض"
              placeholder="حدد تاريخ فتح العروض"
              inputType="date"
              formControl={form.control}
            />
          </section>
        </div>

        <Separator className="w-full bg-primary-gray h-[2px] " />

        <div className="flex flex-col gap-5 w-full md:flex-row space-x-4">
          <section className="w-full">
            <TenderFieldView
              name="region"
              label="المنطقة"
              placeholder="اختر المنطقة"
              inputType="combobox"
              options={regions}
              formControl={form.control}
            />
          </section>
          <section className="w-full">
            <TenderFieldView
              name="city"
              label="المدينة"
              placeholder="اختر المدينة"
              inputType="combobox"
              options={cities}
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px] " />
        {/**/}<section className="flex">
          <TenderFieldView
            name="receiveDocumentsType"
            label="كيفية استلام العظاءات"
            placeholder="اختر نوع استلام العظاءات"
            inputType="radio"
            options={[
              {value: "Both", label: " (عطاء فني مع عطاء مالي) كلاهما"},
              {
                value: "OneByOne",
                label: "العطاء الفني منفصل عن العطاء المالي",
              },
            ]}
            formControl={form.control}
          />
        </section>
        
        <section className="flex">
          <TenderFieldView
            name="Paylater"
            label="شروط الدفع؟"
            placeholder="اختر نوع الدفع بالاجل"
            inputType="radio"
            options={[
              {value: "Yes", label: "بالأجل"},
              {
                value: "No",
                label: "نقداً",
              },
            ]}
            formControl={form.control}
          />
        </section>
        <TenderFieldView
          name="contactInfo"
          label="معلومات الاتصال"
          placeholder="أدخل معلومات الاتصال"
          formControl={form.control}
        />
        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
                {isEmpty(myCompany)?
               <Alert severity="error">
                لتمكينك من إنشاء فرصة، يرجى تعبئة معلومات شركتكم
               </Alert>
                :
                <OfferSubmitView isLoading={isLoading} />
                }
      </form>
    </Form>
  );
};

export default TenderFormView;
