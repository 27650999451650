import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Box } from '@mui/material';
import { isNull } from 'lodash';

interface Product {
  unitName: string;
  quantity: number;
  unitPrice: number;
  attachment: File | null;
}

interface TenderQuotation {
    tender: { title: string; id: string };
    id: string;
   // description: string;
    status: string;
   // value: number;
    products:Product[],
    deliverDays:number,
    DeadLineDate:string,
    paymentMethod:string,
    contactMethod:string,
    company: {
      nameAr: string;
      website: string;
      address: string;
      contactInfo: string;
    };
  }

interface ComparisonModalProps {
  open: boolean;
  onClose: () => void;
  offers: TenderQuotation[];
}

const ComparisonModal: React.FC<ComparisonModalProps> = ({ open, onClose, offers }) => {
    console.log(offers,"SSS")
    const getDAte = (days:string)=>{
      switch(days){
        case "7":return "7 أيام"
        case "14":return "14 أيام"
        case "30":return "30 أيام"
        default:return "7 أيام"
      }
    }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xl"} fullWidth>
      <DialogTitle>مقارنة عروض الأسعار</DialogTitle>
      <DialogContent>
    <Grid container spacing={2}>
      {offers.map(quotation=>{
        return (
          <Grid item xs={12} lg={6}>
          <Typography variant="h6" align="right">اسم الشركة:</Typography>
          <Typography variant="body1" align="right" sx={{ mb: 3 }}>
            {quotation.company?.nameAr}
          </Typography>
          <Typography mb={1}>الحالة: {quotation.status}</Typography>
          <Typography variant="h6" align="right">قائمة المنتجات</Typography>
          <Box sx={{ overflowX: 'auto', mt: 2 }}>
      <TableContainer sx={{ maxWidth: '100%', borderRadius: 2, overflow: 'hidden' }}>
        <Table sx={{ minWidth: 650, tableLayout: 'fixed' }}>
               <TableHead>
                 <TableRow>
                   <TableCell align="right">اسم البند</TableCell>
                   <TableCell align="right">الكمية</TableCell>
                   <TableCell align="right">سعر الوحدة</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {quotation.products.map((product :any, index:number) => (
                   <TableRow key={index}>
                     <TableCell align="right">
                       {product.unitName}  
                     </TableCell>
                     <TableCell align="right">
                     {product.quantity}
                     </TableCell>
                     <TableCell align="right">
                     {product.unitPrice}
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
           </Box>
           <Typography variant="h6" align="right">ضريبة القيمة المضافة: {(quotation.products.reduce((acc :any, product:any) => acc + product.unitPrice * product.quantity, 0)*0.15).toFixed(2)} ريال سعودي</Typography>
           <Typography variant="h6" align="right">ملخص إجمالي السعر: {quotation.products.reduce((acc :any, product:any) => acc + product.unitPrice * product.quantity, 0).toFixed(2)} ريال سعودي</Typography>
<Grid container spacing={2}> 
        <Grid item xs={12} lg={6}>
        <Typography mb={1}>مدة التسليم المقترحة (أيام): {isNull(quotation.deliverDays) ? "" :quotation.deliverDays}</Typography>
        {quotation.deliverDays}
        </Grid>
        <Grid item xs={12} lg={6}>
        <Typography mb={1}>مدة صلاحية عرض السعر (بعد التقديم): {isNull(quotation.DeadLineDate) ? "" :getDAte(quotation.DeadLineDate)}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
      <Typography mb={1}>ماهي طريقة الدفع ؟: {isNull(quotation.paymentMethod) ? "" :quotation.paymentMethod === "1"?"دفع مباشر":"دفع بالاجل (للحصول على هذه الخدمة يتوجب عليك التواصل مع ميدكس بعد قبول عرض السعر)"}</Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
      <Typography mb={1}>جهات الاتصال: {isNull(quotation.contactMethod) ? "" :quotation.contactMethod === "1"?"البريد الإلكتروني": "الهاتف"}</Typography>
      </Grid>
           </Grid>
        )
      })}
    </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          إغلاق
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComparisonModal;
