import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {Input} from "@/app/global-components/input";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import ForgotPasswordSchema from "@/app/features/auth/@core/schema/forgot-password-schema";

interface ForgotPasswordFormFieldProps {
  name: FieldPath<z.infer<typeof ForgotPasswordSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof ForgotPasswordSchema>>;
}

const ForgotPasswordFieldView: React.FC<ForgotPasswordFormFieldProps> = ({
  name,
  label,
  placeholder,
  inputType,
  formControl,
}) => {
  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem className="mb-4">
          <FormLabel className="mb-2">{label}</FormLabel>
          <FormControl>
            <Input
              className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-sm"
              placeholder={placeholder}
              type={inputType || "text"}
              {...field}
            />
          </FormControl>
          <FormMessage className="text-secondary-brightYellow text-xs" />
        </FormItem>
      )}
    />
  );
};

export default ForgotPasswordFieldView;
