import React from 'react';

interface WelcomeBoxProps {
  backgroundColor?: string;
  textColor?: string;
}

const WelcomeBox: React.FC<WelcomeBoxProps> = ({
  backgroundColor = '#fff', // Default background color
  textColor = '#333' // Default text color
}) => {
    const formatDateInArabic = (date: Date) => {
        return new Intl.DateTimeFormat('ar-EG', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(date);
      };
  return (
    <div 
      style={{
        padding: '0',
        borderRadius: '8px',
        backgroundColor,
        color: textColor,
        //boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        height:"100%"
      }}
    >
      <h1 style={{ margin: '0 0 0 0',fontSize:'3em' }}>{formatDateInArabic(new Date())}</h1>
    </div>
  );
};

export default WelcomeBox;
