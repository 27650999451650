import React from "react";
import { Send, Assignment, CheckCircle, MonetizationOn } from "@mui/icons-material";

const workflowSteps = [
  {
    title: "طرح المناقصات",
    description: "ينشر المشتري مناقصته على المنصة بكل تفاصيلها.",
    icon: <Send fontSize="large" style={{ color: "#fff" }} />
  },
  {
    title: "تقديم العروض",
    description: "يتقدم الموردون بعروضهم الأسعار عبر المنصة.",
    icon: <Assignment fontSize="large" style={{ color: "#fff" }} />
  },
  {
    title: "التقييم والمقارنة",
    description: "يقوم المشتري بمقارنة العروض واختيار العرض الأفضل.",
    icon: <CheckCircle fontSize="large" style={{ color: "#fff" }} />
  },
  {
    title: "التمويل",
    description: "نوفر حلول تمويلية مرنة لتلبية احتياجاتك.",
    icon: <MonetizationOn fontSize="large" style={{ color: "#fff" }} />
  }
];

const WorkflowTimeline: React.FC = () => {
  return (
    <div className="flex flex-col items-center p-6 ">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">كيفية عمل ميدكس</h2>
      <div className="flex flex-wrap justify-center gap-6">
        {workflowSteps.map((step, index) => (
          <div
            key={index}
            className="flex flex-col items-center text-center p-4 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105"
            style={{ width: "250px", height: "auto" }}
          >
            {/* Icon as Card Image */}
            <div className="flex items-center justify-center w-16 h-16 mb-4 bg-indigo-500 rounded-full">
              {step.icon}
            </div>
            {/* Text Content */}
            <div>
              <h3 className="font-semibold text-lg text-gray-800">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkflowTimeline;
