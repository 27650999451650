import {type ClassValue, clsx} from "clsx";
import {twMerge} from "tailwind-merge";
import CryptoJS from "crypto-js";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Replace with your actual secret key
const secretKey: string = import.meta.env.VITE_SECRET_KEY;

// Encrypt data before storing
export const encryptData = (data: Record<string, unknown>): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

// Decrypt data after retrieving
export const decryptData = (cipherText: string): Record<string, unknown> => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Store encrypted data in localStorage
export const storeData = (key: string, data: Record<string, unknown>): void => {
  console.log(key)
  const encryptedData = encryptData(data);
  localStorage.setItem(key, encryptedData);
};

// Retrieve and decrypt data from localStorage
export const getData = (key: string): Record<string, unknown> | null => {
  const encryptedData = localStorage.getItem(key);
  return encryptedData ? decryptData(encryptedData) : null;
};

export const useAuthRedirect = () => {
  const navigate = useNavigate();
  const accessToken = getData("tokens")?.accessToken;

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);
};
