import type {Control, FieldPath} from "react-hook-form";
import {z} from "zod";
import {Input} from "@/app/global-components/input";
import ConfirmSchema from "../../../../@core/schema/confirm-schema";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/global-components/form";
import useConfirm from "@/app/features/auth/presentation/confirm/components/hook/use-confirm";

interface EmailConfirmatrFormFieldProps {
  name: FieldPath<z.infer<typeof ConfirmSchema>>;
  label: string;
  placeholder: string;
  description?: string;
  inputType?: string;
  formControl: Control<z.infer<typeof ConfirmSchema>>;
}

const ConfirmFormFieldView: React.FC<EmailConfirmatrFormFieldProps> = ({
  name,
  label,
  placeholder,
  inputType,
  formControl,
}) => {
  const {resendCode, isLoading} = useConfirm();

  const handleResendCode = async () => {
    try {
      await resendCode();
      
    } catch (err) {
      // Error is handled in the hook, but you can add additional handling here if needed
    }
  };

  return (
    <FormField
      control={formControl}
      name={name}
      render={({field}) => (
        <FormItem className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <FormLabel className="text-sm">{label}</FormLabel>
            <p className="text-xs text-textcolor-mediumGray">
              <span
                className="underline text-textcolor-black cursor-pointer"
                onClick={handleResendCode}
                style={{pointerEvents: isLoading ? "none" : "auto"}}
              >
                {isLoading ? "جاري إعادة الإرسال..." : "إعادة إرسال الرمز؟"}
              </span>
            </p>
          </div>

          <FormControl>
            <Input
              className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-sm"
              placeholder={placeholder}
              type={inputType || "text"}
              {...field}
            />
          </FormControl>
          <FormMessage className="text-xs text-secondary-brightYellow" />
        </FormItem>
      )}
    />
  );
};

export default ConfirmFormFieldView;
