import Footer from "../../footer";
//import FooterPage from "./footer/footer-page";
import HeaderPage from "./header/header-page";
import WorkflowTimeline from "./header/work-flow";
import NavPage from "./nav/nav-page";
import OffersPage from "./offers/offers-page";

function LandingPage() {
  return (
    <>
   <NavPage 
      isForm={1}
      />
    <div className="flex flex-col min-h-screen" style={{background: "#f8fafa !important",paddingTop:80}}>      
      <HeaderPage />
      <WorkflowTimeline />
      <OffersPage />
      <Footer />
    </div>
    </>
  );
}

export default LandingPage;
