import ForgotPasswordSchema from "@/app/features/auth/@core/schema/forgot-password-schema";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import ForgotPasswordFieldView from "./forgot-password-field-view";
import ForgotPasswordSubmitView from "./forgot-password-submit-view";
import {useNavigate} from "react-router-dom";
import useForgotPassword from "@/app/features/auth/presentation/forgot-password/hook/useForgotPassword"; // Import the new hook

const ForgotPasswordFormView = () => {
  const navigate = useNavigate();
  const {sendForgotPasswordEmail, isLoading, error} = useForgotPassword(); // Use the new hook

  const form = useForm<z.infer<typeof ForgotPasswordSchema>>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof ForgotPasswordSchema>) => {
    try {
      await sendForgotPasswordEmail(values);
      sessionStorage.setItem("emailForgotPassword", values.email);
      navigate("/resetpassword"); // Navigate to the reset password page
    } catch (err) {
      // Error is already handled in the hook
      console.error(err);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <ForgotPasswordFieldView
          name="email"
          label="البريد الإلكتروني"
          placeholder="أدخل البريد الإلكتروني"
          inputType="email"
          formControl={form.control}
        />
        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
        <ForgotPasswordSubmitView isLoading={isLoading} />
      </form>
    </Form>
  );
};

export default ForgotPasswordFormView;
