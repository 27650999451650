import React from "react";
import {MdClose} from "react-icons/md";
import {Button} from "@/app/global-components/button";
import {Link as LinkTo, useNavigate} from "react-router-dom";
import {getData} from "@/lib/utils";
import { RootState } from "@/store/type";
import { useDispatch, useSelector } from "react-redux";
import {FaHome, FaUser, FaBullhorn,FaBuilding ,FaDollarSign} from "react-icons/fa";
import { updateAuthUser } from "@/store/authSlice";
import { isUndefined } from "lodash";
interface SidebarProps {
  sidebarOpen: boolean;
  toggleSidebar: (open: boolean) => void;
}

const SidebarView: React.FC<SidebarProps> = ({sidebarOpen, toggleSidebar}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const userId = authUser?.id;
  const userRole = isUndefined(authUser?.role)?"":authUser?.role;
  const accesToken = getData("tokens")?.accessToken;
  return (
    <nav
      className={`w-full md:w-[400px] h-screen fixed top-0 p-0 px-2 ${
        sidebarOpen ? "right-0" : "-right-full md:-right-[400px]"
      } z-[9999] bg-primary-white transition-all duration-300`}
    >
      <div className="flex justify-between items-center py-5">
        <div className="flex items-center">
          <h1 className="text-textcolor-white text-lg ml-4">Medex</h1>
        </div>
        <Button
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="transition-all duration-300 cursor-pointer"
        >
          <MdClose className="text-textcolor-white" />
        </Button>
      </div>

      <ul className="flex flex-col items-center py-10">
      {accesToken ? (
              <>
               <li className="font-semibold text-xs cursor-pointer my-2 flex items-center">
              <LinkTo
               className="px-4 py-2 flex items-center"
               to={`/`}
              >
               <span className="hidden md:inline-block ml-2">
                  <FaHome />
                </span>
                الرئيسية
              </LinkTo>
            </li>
            <li className="font-semibold text-xs cursor-pointer my-2 flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/profile/${userId}`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaUser />
                  </span>
                  ملفي الشخصي
                </LinkTo>
              </li>
              <li className="font-semibold text-xs cursor-pointer my-2 flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/tenders/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaBullhorn />
                  </span>
                  الفرص
                </LinkTo>
              </li>
              <li className="font-semibold text-xs cursor-pointer my-2 flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/company/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaBuilding />
                  </span>
                  شركة
                </LinkTo>
              </li>
              <li className="font-semibold text-xs cursor-pointer my-2 flex items-center">
              {
                userRole === "Buyer"?
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/tender/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaDollarSign />
                  </span>
                  اضافة عرض سعر
                </LinkTo>
                :
                <LinkTo
                className="px-4 py-2 flex items-center"
                to={`/QuatationtendersrePage/`}
              >
                <span className="hidden md:inline-block ml-2">
                  <FaDollarSign />
                </span>
                عروض الأسعار
              </LinkTo>
                }
              </li>
              <li className="font-semibold text-xs cursor-pointer my-2 flex items-center">                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/AboutUs`}
                >
                  
                  من نحن                
                </LinkTo>
              </li>
              </>
            ) : (
              <>
              <li className="font-semibold text-sm cursor-pointer flex items-center">
            
              <LinkTo
              className="px-4 py-2 flex items-center"
              to={`/`}
              >  <span className="hidden md:inline-block ml-2">
              <FaHome />
            </span>
                الرئيسية
              </LinkTo>
            </li>
            <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
            <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/tenders/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaBullhorn />
                  </span>
            أحدث الفرص
          </LinkTo>
            </li>
            <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
            <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/AboutUs`}
                >
                  
                  من نحن                
                </LinkTo>
            </li>
            </>
            )}

      </ul>
      <ul className="flex flex-row justify-between items-center py-10">
        <li className="font-semibold text-xs cursor-pointer flex items-center">
        {!accesToken ? <LinkTo
                to="/login"
                className="px-4 py-2 hover:text-secondary-brightYellow text-textcolor-black flex items-center"
              >
                تسجيل الدخول
              </LinkTo>
              :
              <div
                onClick={()=>{
                  dispatch(updateAuthUser(null));
                  localStorage.removeItem("tokens")
                  localStorage.removeItem("userInfo")
                  navigate('/')
                }}
                className="px-4 py-2 hover:text-secondary-brightYellow text-textcolor-black flex items-center"
              >
                تسجيل خروج
              </div>
            }
        </li>
        <li className="font-semibold text-xs cursor-pointer flex items-center">
          <a
            href="/"
            className="rounded-lg bg-secondary-brightYellow px-4 py-2 text-primary-dark"
          >
            ابدأ الآن
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default SidebarView;
