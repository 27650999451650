import React, {useEffect, useMemo, useState} from "react";
import OpportunityCard from "@/app/global-components/opportunity-card";
import {Input} from "@/app/global-components/input";

import {MultiSelect} from "@/app/global-components/multi-select";
// Exemple de données pour les opportunités
const opportunities = [
  {
    id: 1,
    title: "تأمين وتوريد طابعة",
    category: "أجهزة الحاسب الآلي",
    location: "الرياض",
    description:
      "تأمين وتوريد طابعة لأجهزة الحاسب الآلي في منطقة الرياض. هذه الفرصة مخصصة للشركات الصغيرة جدًا.",
    status: "متاحة",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000,// 9h 52m restantes
    companyId:"123123"
  },
  {
    id: 2,
    title: "تطوير موقع إلكتروني",
    category: "تكنولوجيا المعلومات",
    location: "جدة",
    description:
      "فرصة لتطوير موقع إلكتروني احترافي لشركة في جدة. يتطلب خبرة في تطوير الواجهات الأمامية والخلفية.",
    status: "قيد الانتظار",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000,// 2 jours restants
    companyId:"123123"
  },
  {
    id: 3,
    title: "خدمات تنظيف مكاتب",
    category: "خدمات عامة",
    location: "الدمام",
    description:
      "تقديم خدمات تنظيف مكاتب في مدينة الدمام. تشمل الخدمة تنظيف شامل وتوفير مواد التنظيف.",
    status: "منتهية",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 1 jour écoulé
    companyId:"123123"
  },
  {
    id: 4,
    title: "تأجير معدات طبية",
    category: "معدات طبية",
    location: "المدينة المنورة",
    description:
      "تأجير معدات طبية للمستشفيات والعيادات في المدينة المنورة. يشمل التأجير أجهزة متقدمة للتحاليل.",
    status: "متاحة",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000,// 5h restantes
    companyId:"123123"
  },
  {
    id: 5,
    title: "تنظيم فعاليات",
    category: "خدمات تنظيم",
    location: "الرياض",
    description:
      "تنظيم فعاليات اجتماعية وشركات في الرياض. يشمل تنظيم كل جوانب الفعالية من التخطيط إلى التنفيذ.",
    status: "قيد الانتظار",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 3 jours restants
    companyId:"123123"
  },
  {
    id: 6,
    title: "استشارات مالية",
    category: "استشارات",
    location: "الخبر",
    description:
      "تقديم استشارات مالية للشركات والأفراد في الخبر. يشمل التحليل المالي وتقديم الاستراتيجيات المناسبة.",
    status: "متاحة",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 6 jours restants
    companyId:"123123"
  },
  {
    id: 7,
    title: "تطوير تطبيقات موبايل",
    category: "تكنولوجيا المعلومات",
    location: "الرياض",
    description:
      "تطوير تطبيقات موبايل لآيفون وأندرويد. يتطلب مهارات في تطوير الواجهات وتجربة المستخدم.",
    status: "قيد الانتظار",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000,// 4 jours restants
    companyId:"123123"
  },
  {
    id: 8,
    title: "تأمين معدات مكتبية",
    category: "أثاث وتجهيزات مكتبية",
    location: "الرياض",
    description:
      "تأمين معدات مكتبية مثل الطابعات، أجهزة الكمبيوتر، والأثاث المكتبي في الرياض.",
    status: "منتهية",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 2 jours écoulés
    companyId:"123123"
  },
  {
    id: 9,
    title: "خدمات استشارية قانونية",
    category: "استشارات",
    location: "جدة",
    description:
      "تقديم استشارات قانونية للأفراد والشركات في جدة. يشمل تقديم المشورة القانونية والتمثيل أمام المحكمة.",
    status: "متاحة",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 7h restantes
    companyId:"123123"
  },
  {
    id: 10,
    title: "تأجير سيارات",
    category: "خدمات عامة",
    location: "الرياض",
    description:
      "تأجير سيارات متنوعة للأفراد والشركات في الرياض. تشمل الخدمة تأجير سيارات فاخرة واقتصادية.",
    status: "قيد الانتظار",
    city: "الرياض",
    region: "المملكة العربية السعودية",
    categories: ["أجهزة طبية"],
    endDate: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000).toISOString(),
    remainingTime: Date.now() + 10 * 24 * 60 * 60 * 1000, // 1 jour restant,
    companyId:"123123"
  },
];

const TenderSquarePage: React.FC = () => {
  const [visibleCards, setVisibleCards] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCategories, setFilterCategories] = useState<string[]>([]);
  const [filteredOpportunities, setFilteredOpportunities] =
    useState(opportunities);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight
    ) {
      setVisibleCards((prevVisibleCards) => prevVisibleCards + 10);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useMemo(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filtered = opportunities.filter(
      (opportunity) =>
        opportunity.title.toLowerCase().includes(lowercasedSearchTerm) &&
        (filterCategories.length === 0 ||
          filterCategories.includes("all") ||
          filterCategories.includes(opportunity.category))
    );
    setFilteredOpportunities(filtered);
  }, [searchTerm, filterCategories]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6 flex flex-col items-center md:items-start text-right">
        <h2 className="text-3xl font-semibold tracking-tight mb-2 text-primary-dark">
          إليك قائمة بالفرص المتاحة لك !
        </h2>
      </div>
      <div className="flex flex-col md:flex-row md:items-center mb-6 gap-4">
        <Input
          type="text"
          placeholder="بحث..."
          value={searchTerm}
          className="focus-visible:ring-secondary-brightYellow w-full py-2 px-3 text-xs flex items-center md:text-base"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <MultiSelect
          options={[
            {value: "all", label: "جميع الفئات"},
            {value: "أجهزة الحاسب الآلي", label: "أجهزة الحاسب الآلي"},
            {value: "أثاث وتجهيزات مكتبية", label: "أثاث وتجهيزات مكتبية"},
            {value: "معدات طبية", label: "معدات طبية"},
            {value: "تكنولوجيا المعلومات", label: "تكنولوجيا المعلومات"},
            {value: "خدمات عامة", label: "خدمات عامة"},
          ]}
          onValueChange={(values: string[]) => setFilterCategories(values)}
          defaultValue={filterCategories}
          placeholder="اختر الفئة"
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {filteredOpportunities.slice(0, visibleCards).map((opportunity) => (
          <OpportunityCard key={opportunity.id} opportunity={opportunity} />
        ))}
      </div>
    </div>
  );
};

export default TenderSquarePage;
