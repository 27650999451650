import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ProfileSchema} from "../../profile-schema";
import ProfileFieldView from "./profile-field-view";
import {useParams} from "react-router-dom";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import {Separator} from "@/app/global-components/separator";
import useProfile from "../hook/use-profile";
//import {useAuthRedirect} from "@/lib/utils";
import ProfileSubmitView from "./profile-submit-view";
import regionsData from "@/app/features/company/data/regions_lite.json";
import citiesData from "@/app/features/company/data/cities_lite.json";
//import {getData} from "@/lib/utils";
//import {isUndefined} from 'lodash';
interface Region {
  region_id: number;
  name_ar: string;
}

interface City {
  city_id: number;
  region_id: number;
  name_ar: string;
}

interface Option {
  value: string;
  label: string;
}

const EditProfileFormView = () => {
  const {id} = useParams();
  const {updateUser,getUserProfile, isLoading, error} = useProfile();
  const [regions, setRegions] = useState<Option[]>([]);
  const [cities, setCities] = useState<Option[]>([]);
  const [isNewUser, setIsNewUser] = useState(false);
  

  const form = useForm<z.infer<typeof ProfileSchema>>({
    resolver: zodResolver(ProfileSchema),
    defaultValues: {
      id: null,
      nickName: "",
      email: "",
      phoneNumber: "",
      role: "عميل",
      gender: null,
      accountType: null,
      region: "",
      city: "",
      identityType: "Citizen",
      identityNo: "",
      residenceNo: "",
      dateOfBirth: null,
    },
  });

  //useAuthRedirect();

  useEffect(() => {
    setRegions(
      regionsData.map((region: Region) => ({
        value: region.region_id.toString(),
        label: region.name_ar,
      }))
    );
  }, []);

  useEffect(() => {
    const selectedRegion = form.watch("region");
    if (selectedRegion) {
      const filteredCities = citiesData
        .filter((city: City) => city.region_id === parseInt(selectedRegion))
        .map((city: City) => ({
          value: city.city_id.toString(),
          label: city.name_ar,
        }));
      setCities(filteredCities);
    } else {
      setCities([]);
    }
  }, [form.watch("region")]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (id) {
        try {
          const userData = await getUserProfile();
          if (userData) {
            console.log(userData,"userData")
            form.reset(userData.data);
            setIsNewUser(false);
          } else {
            setIsNewUser(true);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
          setIsNewUser(true);
        }
      }
    };

    fetchUserProfile();
  }, [id]);

  const onSubmit = async (values: z.infer<typeof ProfileSchema>) => {
    console.log('Form submitted with values:', values);
    try {
      const submissionData = {
        ...values,
        identityNo: values.identityType === "Citizen" ? values.identityNo : null,
        residenceNo: values.identityType === "Resident" ? values.residenceNo : null,
      };

      console.log(submissionData,"ASDASDASD"); // For debugging
      await updateUser( submissionData);
    } catch (err) {
      console.error("Error during profile submission:", err);
    }
  };
  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex xs:flex-col w-full">
          <section className="mt-2">
            <ProfileFieldView
              name="nickName"
              label="الاسم المستعار"
              placeholder="أدخل اسمك المستعار"
              formControl={form.control}
            />
          </section>
          <section className="mt-2">
            <ProfileFieldView
              name="email"
              label="البريد الإلكتروني"
              placeholder="أدخل بريدك الإلكتروني"
              formControl={form.control}
            />
          </section>
          <section className="mt-2">
            <ProfileFieldView
              name="phoneNumber"
              label="رقم الهاتف"
              placeholder="أدخل رقم هاتفك"
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px]" />
        <div className="flex flex-col justify-between sm:flex-row xs:gap-5 py-2 w-full">
          <section className="sm:w-1/2 w-full flex flex-col">
            <ProfileFieldView
              name="region"
              label="اختر المنطقة"
              placeholder="dqwdqwdwq"
              inputType="combobox"
              options={regions}
              formControl={form.control}
            />
          </section>
          <section className="flex flex-col sm:w-1/2 w-full">
            <ProfileFieldView
              name="city"
              label="اختر المدينة"
              placeholder="اختر المدينة"
              inputType="combobox"
              options={cities}
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px]" />
        <div className="flex flex-col md:flex-row">
          <section className="flex-1 mt-2">
            <ProfileFieldView
              name="gender"
              label="الجنس"
              placeholder="اختر الجنس"
              inputType="radio"
              options={[
                {value: "Male", label: "ذكر"},
                {value: "Female", label: "أنثى"},
              ]}
              formControl={form.control}
            />
          </section>
          <section className="flex-1 mt-2">
            <ProfileFieldView
              name="accountType"
              label="نوع الحساب"
              placeholder="اختر نوع الحساب"
              inputType="radio"
              options={[
                {value: "Individual", label: "فردي"},
                {value: "Establishment", label: "مؤسسة"},
              ]}
              formControl={form.control}
            />
          </section>
          <section className="flex-1 sm:w-full mt-2">
            <ProfileFieldView
              name="dateOfBirth"
              label="تاريخ الميلاد"
              placeholder="أدخل تاريخ ميلادك"
              inputType="date"
              formControl={form.control}
            />
          </section>
        </div>
        <Separator className="w-full bg-primary-gray h-[2px]" />
        <ProfileFieldView
          name="identityType"
          label="نوع الهوية"
          placeholder="اختر نوع الهوية"
          inputType="radio"
          options={[
            {value: "Resident", label: "مقيم"},
            {value: "Citizen", label: "مواطن"},
          ]}
          formControl={form.control}
        />

        {form.watch("identityType") === "Citizen" && (
          <ProfileFieldView
            name="identityNo"
            label="رقم الهوية"
            placeholder="أدخل رقم الهوية"
            formControl={form.control}
          />
        )}

        {form.watch("identityType") === "Resident" && (
          <ProfileFieldView
            name="residenceNo"
            label="رقم الإقامة"
            placeholder="أدخل رقم الإقامة"
            formControl={form.control}
          />
        )}

        {error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
        <ProfileSubmitView isLoading={isLoading} isNewUser={isNewUser} />
      </form>
    </Form>
  );
};

export default EditProfileFormView;
