import  { useState } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/app/global-components/button";
import { Form } from "@/app/global-components/form";
import { tenderQuotationSchema, TenderQuotation } from "./tenderQuotationSchema";
import useOffer from "../hook/useTender";
import { useSelector } from "react-redux";
import { RootState } from "@/store/type";
import QuatationFieldView from "./quatation-field-view";
import { Box, Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

interface Product {
    unitName: string;
    quantity: number;
    unitPrice: number;
    attachment: File | null;
}

const QuotationPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const {sendOffertRequest} = useOffer();
    const [acceptTerms, setAcceptTerms] = useState(false);
    const myCompany = useSelector((state: RootState) => state.auth.myCompany);
    const [products, setProducts] = useState<Product[]>([{ unitName: "", quantity: 1,unitPrice:0, attachment: null }]);
    const form = useForm<TenderQuotation>({
        resolver: zodResolver(tenderQuotationSchema),
        defaultValues: {
          id: null,           
          tenderId: id,
          companyId: myCompany?.id,
          userId: authUser?.id,
          status:'Pending',
          products: [],
          deliverDays: 0,
          DeadLineDate: "7",
          paymentMethod: "1",
          contactMethod: "1",
          //acceptTerms: false,
        },
      });
     
      const onSubmit = async (values: TenderQuotation) => {
        try {
            console.log(values);
            await sendOffertRequest(values);
          } catch (err) {
            console.error("Error creating offer Quotation:", err);
          }
      };
      
  const handleGoBack = () => {
    navigate(-1) // Navigate to the previous page
  };
      const addProduct = () => {
        const newProduct = { unitName: "", quantity: 1,unitPrice:0, attachment: null };
        const currentProducts = form.getValues("products") as Product[];
        const updatedProducts = [...currentProducts, newProduct];
        setProducts(updatedProducts); // Update local state
    
        // Update form state
        form.setValue("products", updatedProducts);
      };
      const removeProduct = (index: number) => {
        const currentProducts = form.getValues("products") as Product[];
        const newProducts = currentProducts.filter((_, i) => i !== index);
        setProducts(newProducts);
        form.setValue("products", newProducts);
      };
      const options = [
        { value: "7", label: "7 أيام" },
        { value: "14", label: "14 أيام" },
        { value: "30", label: "30 أيام" },
      ];
      const optionsPayment =[
        { value: "1", label: "دفع مباشر" },
        { value: "2", label: "دفع بالاجل (للحصول على هذه الخدمة يتوجب عليك التواصل مع ميدكس بعد قبول عرض السعر)" },
      ]
      const optionsContact =[
        { value: "1", label: "البريد الإلكتروني" },
        { value: "2", label: "الهاتف" },
      ]
      const totalPrice = products.reduce((acc, product) => acc + product.unitPrice * product.quantity, 0);
      return (
        <Box sx={{ padding: 2, paddingX: { xs: 1, sm: 4 } }} dir="rtl">
            {/* اسم الشركة */}
            <Typography variant="h6" align="right">اسم الشركة:</Typography>
            <Typography variant="body1" align="right" sx={{ mb: 3 }}>
            {myCompany?.nameAr}
            </Typography>
            <div className="space-y-4">
            <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                {/* قائمة المنتجات */}
                <Typography variant="h6" align="right">قائمة المنتجات</Typography>
                <Button className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black  py-2 px-4 text-sm"
                onClick={addProduct}>إضافة منتج</Button>
                <Box sx={{ overflowX: 'auto', mt: 2 }}>
      <TableContainer sx={{ maxWidth: '100%', borderRadius: 2, overflow: 'hidden' }}>
        <Table sx={{ minWidth: 650, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell align="right">اسم البند</TableCell>
              <TableCell align="right">الكمية</TableCell>
              <TableCell align="right">سعر الوحدة</TableCell>
              <TableCell align="right">رفع ملف</TableCell>
              <TableCell align="right">الإجراءات</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => {
                console.log(product)
                return <TableRow key={index}>
                <TableCell align="right">
                <QuatationFieldView
                    name={`products.${index}.unitName`}
                    placeholder="أدخل اسم البند"
                    label=""
                    inputType="text"
                    formControl={form.control}
                  />                 
                </TableCell>
                <TableCell align="right">
                <QuatationFieldView
                    name={`products.${index}.quantity`}
                    placeholder="أدخل الكمية"
                    label=""
                    inputType="number"
                    formControl={form.control}
                  />
                </TableCell>
                <TableCell align="right">
                <QuatationFieldView
                    name={`products.${index}.unitPrice`}
                    placeholder="أدخل السعر"
                    label=""
                    inputType="number"
                    formControl={form.control}
                  />
                </TableCell>
                <TableCell align="right">
                <QuatationFieldView
                    name={`products.${index}.attachment`}
                    placeholder="اختر ملفًا"
                    label=""
                    inputType="file"
                    formControl={form.control}
                  />
                </TableCell>
                <TableCell>
                  <Button color="secondary" onClick={() => removeProduct(index)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
{/* ملخص إجمالي السعر */}
<Typography variant="h6" align="right">ضريبة القيمة المضافة: {(totalPrice * 0.15).toFixed(2)} ريال سعودي</Typography>
<Typography variant="h6" align="right">ملخص إجمالي السعر: {totalPrice.toFixed(2)} ريال سعودي</Typography>

       {/* مدة التسليم المقترحة */}
       <Grid container spacing={2}> 
        <Grid item xs={12} lg={6}>
          <QuatationFieldView
            name="deliverDays"
            placeholder=""
            label="مدة التسليم المقترحة (أيام)"
            inputType="number"
            formControl={form.control}
            />
        </Grid>
        <Grid item xs={12} lg={6}>
        <QuatationFieldView
            name="DeadLineDate"
            placeholder=""
            label="مدة صلاحية عرض السعر (بعد التقديم)"
            inputType="select"
            options={options}
            formControl={form.control}
        />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
      <QuatationFieldView
            name="paymentMethod"
            placeholder=""
            label="ماهي طريقة الدفع ؟"
            inputType="radio"
            options={optionsPayment}
            formControl={form.control}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
      <QuatationFieldView
            name="contactMethod"
            placeholder=""
            label="جهات الاتصال:"
            inputType="radio"
            options={optionsContact}
            formControl={form.control}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
      <FormControlLabel
        sx={{ margin: '5px auto' }}
        control={<Checkbox checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />}
        label="أوافق على الشروط والأحكام"
      />
      </Grid>
      <div className='flex mt-4' style={{ justifyContent: "space-around" }}>
        <Button
          className="flex items-center bg-secondary-brightYellow shadow text-textcolor-black  py-2 px-4 text-sm"
          type="submit"
          disabled={!acceptTerms}>
          تقديم
        </Button>
        <Button variant="secondary" onClick={handleGoBack}>إلغاء</Button>
      </div>
      </form>
            </Form>
             
            </div>
        </Box>
      );
}

export default QuotationPage;


