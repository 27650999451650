import { useState } from "react";
import axios from 'axios';
import { z } from "zod";
import LoginSchema from "@/app/features/auth/@core/schema/login-schema";
import { getData } from "@/lib/utils";
import { useDispatch } from "react-redux"; 
import { updateAuthUser } from "@/store/authSlice";
import api from "@/store/api"; // Import your configured axios instance

const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const login = async (values: z.infer<typeof LoginSchema>) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post("/web/auth/login", values);
      setIsLoading(false);
      dispatch(updateAuthUser(response.data.data.user));
      return response.data;
    } catch (err) {
      setIsLoading(false);

      if (axios.isAxiosError(err)) {
        // Axios-specific error handling
        setError(
          err.response?.data?.message || "An error occurred during login"
        );
        console.error("Axios Error:", err.message);
      } else {
        // General error handling
        setError("An unexpected error occurred");
        console.error("Unexpected Error:", err);
      }
      
      throw err; // Rethrow the error if you want to handle it further up the call stack
    }
  };

  const getMe = async () => {
    setIsLoading(true);
    setError(null);

    try {
      let Token = getData("tokens")?.accessToken;
      const response = await api.get("/web/auth/me", {
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      });
      setIsLoading(false);
      dispatch(updateAuthUser(response.data.data.user));
      return response.data;      
    } catch (err) {
      setIsLoading(false);

      if (axios.isAxiosError(err)) {
        // Handle Axios error, such as token invalidation
        setError(err.response?.data?.message || "Failed to retrieve user data.");
        console.error("Axios Error:", err.message);
        dispatch(updateAuthUser(null));
        localStorage.removeItem("tokens");
        localStorage.removeItem("userInfo");
      } else {
        // Handle other types of errors
        setError("An unexpected error occurred");
        console.error("Unexpected Error:", err);
        dispatch(updateAuthUser(null));
        localStorage.removeItem("tokens");
        localStorage.removeItem("userInfo");
      }
      
      throw err;
    }
  };

  return { login, getMe, isLoading, error };
};

export default useLogin;
