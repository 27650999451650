import React from "react";
import {Button} from "@/app/global-components/button";
import QuotesIcon from "@/assets/svg/Quotes";
import HeaderImage from "@/assets/pexels-karolina-grabowska-4197562.jpg";
import doctor from "@/assets/doctor7-QeJ5elcb.png";

const HeaderPage: React.FC = () => {
  return (
    <section
      id="home"
      className="bg-primary-white mx-auto mb-10 flex flex-col lg:flex-row items-center justify-between pt-20 w-full min-h-[840px] px-4"
    >
      {/* Image Section */}
      <div className="w-full lg:w-1/2 h-full relative mb-12 lg:mb-0 order-1 lg:order-2 hidden lg:block">
        <div className="flex justify-center lg:justify-center relative z-10">
          <img
            className="rounded-lg z-10 w-full max-w-[500px] h-auto object-cover"
            src={HeaderImage}
            alt="مكتب"
          />
          <div className="absolute right-0 bottom-12 max-w-[330px] p-8 bg-gray-800 rounded-lg z-20 flex items-center lg:right-5">
            <div className="absolute right-[-20px] top-[-10px]">
              <QuotesIcon />
            </div>
            <div>
              <p className="text-sm text-white text-right">
                <em>
                  في ميديكس، نقدم حلولاً مبتكرة وواقعية في مجال الأجهزة الطبية
                  والمناقصات الطبية.
                </em>
              </p>
            </div>
          </div>
        </div>
        
      </div>
      
      {/* Text Section */}
      <div className="w-full lg:w-1/2 h-full flex items-center justify-center lg:justify-end text-center lg:text-right order-2 lg:order-1">
        <div>
          
          <h3 className="font-extrabold text-1xl lg:text-2xl mt-4 mb-4">
          ارفع طلباتك واجل مدفوعاتك
          </h3>
          <p className="max-w-[470px] py-4 pb-8 text-sm font-semibold leading-6 mx-auto lg:mr-0 lg:ml-auto">
          نعمل على تبسيط عملية الشراء من خلال منصة ذكية تربطك مباشرة بأفضل الموردين وتوفر لك مقارنة أسعار فورية وحلول تمويل مرنة لتلبية احتياجاتك.
          </p>
          <Button className="bg-primary-purple text-primary-light w-[50%] mx-auto lg:mr-0 lg:ml-auto mb-8 lg:mb-0">
            أبدأ الآن
          </Button>
          <div className="w-full lg:w-1/2 h-full relative mb-12 lg:mb-0 order-1 lg:order-2 block md:hidden">
          <div className="flex justify-center lg:justify-center relative z-10">
          <img
            className="rounded-lg z-10 w-full max-w-[500px] h-auto object-cover"
            src={doctor}
            alt="doctor"
          />
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderPage;
