import React, {useEffect, useState} from "react";
import {Link} from "react-scroll";
import SidebarView from "./components/views/sidebar-view";
import Backdrop from "./components/Backdrop";
import {CiMenuBurger} from "react-icons/ci";
import {FaHome, FaUser, FaBullhorn,FaBuilding ,FaDollarSign} from "react-icons/fa";
import {Button} from "@/app/global-components/button";
import {Link as LinkTo,useNavigate} from "react-router-dom";
import {getData} from "@/lib/utils";
import { getMyCompany,updateAuthUser } from "@/store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/type";
import { isUndefined } from "lodash";
interface NavPageProps {
  isForm: number; // Specify the type of the isForm prop
}

const NavPage: React.FC<NavPageProps> = ({ isForm }) => {
  const dispatch = useDispatch();
  const [y, setY] = useState<number>(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState<boolean>(false);
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const userRole = isUndefined(authUser?.role)?"":authUser?.role;
  
  const userId = authUser?.id;
  const accesToken = getData("tokens")?.accessToken;
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);
console.log(isForm,"isForm")
  return (
    <>
      <SidebarView sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <nav
        className={`fixed top-0 left-0 w-full z-50 ${
          y > 100 ? "h-16" : "h-20"
        } flex items-center justify-center bg-primary-white transition-all duration-300 shadow`}
      >
        <div className="container flex px-2 justify-between items-center h-full text-black">
          <Link
            className="flex items-center text-textcolor-black cursor-pointer"
            to="home"
            smooth={true}
          >
    <h1 className="ml-4 text-3xl font-extrabold bg-clip-text text-black">
  Med<span className="text-black">e</span>x
</h1>

          </Link>
          <Button
            className="outline-none text-textcolor-black border-0 bg-transparent h-3/5 px-4 md:hidden"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <CiMenuBurger />
          </Button>
          <ul className="hidden md:flex items-center">
            
            {accesToken ? (
              <>
               <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
              <LinkTo
               className="px-4 py-2 flex items-center"
               to={`/`}
              >
               <span className="hidden md:inline-block ml-2">
                  <FaHome />
                </span>
                الرئيسية
              </LinkTo>
            </li>
              <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/profile/${userId}`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaUser />
                  </span>
                  ملفي الشخصي
                </LinkTo>
              </li>
              <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/tenders/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaBullhorn />
                  </span>
                  الفرص
                </LinkTo>
              </li>
              <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/company/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaBuilding />
                  </span>
                  شركة
                </LinkTo>
              </li>
              {
                userRole === "Buyer"?
                <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/tender/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaDollarSign />
                  </span>
                  اضافة عرض سعر
                </LinkTo>
              </li>
                :<li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/QuatationtendersrePage/`}
                >
                  <span className="hidden md:inline-block ml-2">
                    <FaDollarSign />
                  </span>
                  عروض الأسعار
                </LinkTo>
              </li>
              }
              
              <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
                <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/AboutUs`}
                >
                  
                  من نحن                
                </LinkTo>
              </li>
              </>
            ) : (
              <>
              <li className="font-semibold text-sm cursor-pointer flex items-center">
              {isForm === 1 ? 
              <Link
                activeClass="active"
                className="px-4 py-2 text-textcolor-black flex items-center"
                to="home"
                spy={true}
                smooth={true}
                offset={-80}
              >
                <span className="hidden md:inline-block ml-2">
                  <FaHome />
                </span>
                الرئيسية
              </Link> 
              :
              <LinkTo
              className="px-4 py-2 flex items-center"
              to={`/`}
              >  <span className="hidden md:inline-block ml-2">
              <FaHome />
            </span>
                الرئيسية
              </LinkTo>
              }         
            </li>
            <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
              <Link
                activeClass="active"
                className="px-4 py-2 flex items-center"
                to="latestOpportunities"
                spy={true}
                smooth={true}
                offset={-60}
              >
                <span className="hidden md:inline-block ml-2">
                  <FaBullhorn />
                </span>
                أحدث الفرص
              </Link>
            
          
            </li>
            <li className="font-semibold text-textcolor-black text-sm cursor-pointer flex items-center">
            <LinkTo
                  className="px-4 py-2 flex items-center"
                  to={`/AboutUs`}
                >
                  
                  من نحن                
                </LinkTo>
            </li>
            </>
            )}

           
          </ul>
          <ul className="hidden md:flex items-center">
            <li className="font-semibold text-sm cursor-pointer">
            {!accesToken ? <LinkTo
                to="/login"
                className="px-4 py-2 hover:text-secondary-brightYellow text-textcolor-black flex items-center"
              >
                تسجيل الدخول
              </LinkTo>
              :
              <div
                onClick={()=>{
                  dispatch(updateAuthUser(null));
                  dispatch(getMyCompany(null)); 
                  localStorage.removeItem("tokens")
                  localStorage.removeItem("userInfo")
                  navigate('/')
                }}
                className="px-4 py-2 hover:text-secondary-brightYellow text-textcolor-black flex items-center"
              >
                تسجيل خروج
              </div>
            }
            </li>
            <li className="font-semibold text-sm cursor-pointer flex items-center">
              <a
                href="/"
                className="rounded-lg bg-secondary-brightYellow text-primary-dark px-4 py-2"
              >
                ابدأ الآن
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavPage;
