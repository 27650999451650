import NavPage from "../landingPage/presentation/nav/nav-page";
import Footer from "../footer";
import QuotationPage from "./components/view/quatation-Form-view";
export default function SendOffert() {
  return (
    <div className="flex flex-col min-h-screen" style={{background: "#f8fafa !important"}}>
 <NavPage 
      isForm={0}
      />
    <div className="xs:flex xs:flex-col xs:justify-center items-center sm:shadow-lg" style={{paddingTop:80}}>
      <main className="flex min-h-screen items-center justify-center w-full sm:w-3/5 flex-col">
        <section className="w-full max-w-xs xs:justify-center bg-primary-white p-5 rounded-md xs:max-w-5xl py-6">
          <QuotationPage/>
        </section>
      </main>
    </div>
    <Footer />
    </div>
  );
}