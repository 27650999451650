import * as z from "zod";

// Enums
const RoleEnum = z.enum(["Admin", "عميل","Client","Buyer","Seller"]);
const GenderEnum = z.enum(["Male", "Female"]);
const AccountTypeEnum = z.enum(["Individual", "Establishment"]);

// Profile Schema
export const ProfileSchema = z
  .object({
    id: z.string().nullable(),
    nickName: z.string().min(1, {message: "الاسم المستعار مطلوب"}),
    email: z.string().email({message: "عنوان البريد الإلكتروني غير صالح"}),
    phoneNumber: z.string().min(1, {message: "رقم الهاتف مطلوب"}),
    identityType: z.enum(["Citizen", "Resident"]),
    role: RoleEnum,
    gender: GenderEnum.nullable(),
    accountType: AccountTypeEnum.nullable(),
    region: z.string().min(1, {message: "المنطقة مطلوبة"}),
    city: z.string().min(1, {message: "المدينة مطلوبة"}),
    identityNo: z.string().nullable().optional(),
    residenceNo: z.string().nullable().optional(),
    dateOfBirth: z.string().nullable(),
  })
  .refine(
    (data) => (data.identityType === "Citizen" ? !!data.identityNo : true),
    {
      message: "رقم الهوية مطلوب للمواطن",
      path: ["identityNo"],
    }
  )
  .refine(
    (data) => (data.identityType === "Resident" ? !!data.residenceNo : true),
    {
      message: "رقم الإقامة مطلوب للمقيم",
      path: ["residenceNo"],
    }
  );
