import { getData } from '@/lib/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

// Define the AuthState interface
export interface AuthState {
  authUser: any | null; // Replace 'any' with your specific user type if available
  loadUser: boolean;
  myCompany: any | null;
}

// Define the initial state
const initialState: AuthState = {
  authUser: isUndefined(getData("userInfo"))?null:getData("userInfo"),
  myCompany:null,
  loadUser: false
};

// Create the slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuthUser(state, action: PayloadAction<any>) {
      state.authUser = action.payload;
      state.loadUser = true;
    },
    updateLoadUser(state, action: PayloadAction<boolean>) {
      state.loadUser = action.payload;
    },
    getMyCompany(state,action: PayloadAction<any>){
      state.myCompany = action.payload
    }
  },
});

// Export actions
export const { updateAuthUser, updateLoadUser,getMyCompany } = authSlice.actions;

// Export the reducer and the AuthState type
export default authSlice.reducer;
