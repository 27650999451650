import React from 'react';
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import RegisterFieldView from "./register-field-view";
import RegisterSchema from "@/app/features/auth/@core/schema/register-schema";
import {z} from "zod";
import {Form} from "@/app/global-components/form";
import RegisteSubmitView from "./register-submit-view";
import {useNavigate} from "react-router-dom";

import useRegister from "@/app/features/auth/presentation/register/hooks/use-register"; // Import the new hook

const RegisterFormView = () => {
  const navigate = useNavigate();
  const [Error,SetError] = React.useState("")
  const {register, isLoading, error} = useRegister(); // Use the new hook

  const form = useForm<z.infer<typeof RegisterSchema>>({
    resolver: zodResolver(RegisterSchema),
    defaultValues: {
      email: "",
      nickName: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof RegisterSchema>) => {
    try {
      const response = await register(values);
      sessionStorage.setItem("email", values.email);
      console.log(response);
      if(response.isSuccess){
        navigate("/confirm");
        SetError("")
      }else{
        SetError(response.message)
      }      
    } catch (err) {
      // Error is already handled in the hook
      console.error(err);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <RegisterFieldView
          name="nickName"
          label="اسم المستخدم"
          placeholder="اسم المستخدم"
          formControl={form.control}
        />

        <RegisterFieldView
          name="email"
          label="البريد الإلكتروني"
          placeholder="البريد الإلكتروني"
          inputType="email"
          formControl={form.control}
        />

        <RegisterFieldView
          name="password"
          label="كلمة المرور"
          placeholder="كلمة المرور"
          description="وصف كلمة المرور"
          inputType="password"
          formControl={form.control}
        />

        <RegisteSubmitView isLoading={isLoading} />

        {Error || error && (
          <p className="text-secondary-brightYellow text-sm mt-2">{error}</p>
        )}
      </form>
    </Form>
  );
};

export default RegisterFormView;
