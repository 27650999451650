import React from 'react';
import ComparisonModal from '@/app/features/landingPage/presentation/lobby/responsiveSeller/Wedgits/ComparisonModal';
import QuotationDetailsModal from '@/app/features/landingPage/presentation/lobby/responsiveSeller/Wedgits/QuotationDetailsModal';
import { isEmpty, isNull } from 'lodash';
import useLobby from '@/app/features/landingPage/presentation/hook/useLobby';
import { Tooltip, IconButton, Checkbox  } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DifferenceIcon from '@mui/icons-material/Difference';
import StatusChip from './Status';

interface OfferQuatationslist {
    offerID?: string;
}
interface Product {
    unitName: string;
    quantity: number;
    unitPrice: number;
    attachment: File | null;
  }
interface TenderQuotation {
    tender: { title: string; id: string };
    id: string;
   // description: string;
    status: string;
   // value: number;
    products:Product[],
    deliverDays:number,
    DeadLineDate:string,
    paymentMethod:string,
    contactMethod:string,
    company: {
      nameAr: string;
      website: string;
      address: string;
      contactInfo: string;
    };
}
const ListofQuatations: React.FC<OfferQuatationslist> = ({offerID}) => {
    const { getListtenderQuotationsByOfferId } = useLobby();
    const [listOffers, setListOffers] = React.useState<TenderQuotation[]>([]);
    const [selectedOffers, setSelectedOffers] = React.useState<TenderQuotation[]>([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedQuotation, setSelectedQuotation] = React.useState<TenderQuotation | null>(null);

    React.useEffect(() => {
        const getListtenderQ = async () => {
          try {
            if(offerID){
                const Total = await getListtenderQuotationsByOfferId(offerID);
                if (Total) {
                  setListOffers(Total.data);
                  setTotalPages(Total.totalPages); // Assuming the API returns totalPages
                } else {
                  setListOffers([]);
                }    
            }            
          } catch (error) {
            console.error("Error fetching Offers:", error);
          }
        };
        getListtenderQ();
    }, [currentPage]);
    const handleNextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        }
    };
    const handleCheckboxChange = (item: TenderQuotation) => {
        setSelectedOffers((prevSelected) => {
          const isSelected = prevSelected.some((offer) => offer.id === item.id);
          let newSelected;
          if (isSelected) {
            newSelected = prevSelected.filter((offer) => offer.id !== item.id);
          } else {
            newSelected = prevSelected.length < 2 ? [...prevSelected, item] : prevSelected;
          }
          return newSelected;
        });
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
      };
      const handleCompare = () => {
        if (selectedOffers.length === 2) {
          setOpenModal(true);
        }
      };
    
      // Handle opening the details modal
      const handleViewDetails = (quotation: TenderQuotation) => {
        setSelectedQuotation(quotation);
      };
    
      // Handle closing the details modal
      const handleCloseDetailsModal = () => {
        setSelectedQuotation(null);
      };
    return (
        <div>
             <div className=" mb-6 gap-4" style={{height:500}}>
             <div
      style={{
        padding: '0',
        borderRadius: '8px',
        textAlign: 'center',
        height: "100%"
      }}
    >
        <h2 className="text-xl font-semibold text-right py-3">عروض الاسعار</h2>
{!isEmpty(listOffers) ? (
        <>
          <table style={{ width: '100%', borderCollapse: 'collapse', margin: "10px 0px" }}>
            <thead>
              <tr>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>
                  {selectedOffers.length === 2 && (
                    <Tooltip title="مقارنة عروض الاسعار">
                      <IconButton color="primary" onClick={handleCompare}>
                        <DifferenceIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </th>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc', textAlign: 'left' }}>فرصة</th>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>عرض سعر</th>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>الشركة</th>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>القيمة</th>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>الحالة</th>
                <th style={{ padding: '5px', borderBottom: '1px solid #ccc' }}></th>
              </tr>
            </thead>
            <tbody>
              {listOffers.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff', // Striped rows
                    cursor: 'pointer',
                    transition: 'background-color 0.3s', // Smooth transition for hover effect
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0f7fa')} // Hover effect
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#f9f9f9' : '#fff')} // Revert back to original color
                >
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>
                    <Checkbox
                      checked={selectedOffers.some((offer) => offer.id === item.id)}
                      onChange={() => handleCheckboxChange(item)}
                      color="primary"
                    />
                  </td>
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc', textAlign: 'left' }}>
                    {isNull(item.tender) ? "" : item.tender.title}
                  </td>
                  {/*<td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{item.description}</td>*/}
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{item.company.nameAr}</td>
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{item.products.reduce((acc :any, product:any) => acc + product.unitPrice * product.quantity, 0).toFixed(2)}</td>
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}> <StatusChip status={isNull(item.status) ? "Opened" :item.status} /></td>
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>
                    <Tooltip title="عرض">
                      <IconButton color="primary" onClick={() => handleViewDetails(item)}>
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                style={{ padding: '8px 16px', border: '1px solid #ccc', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }}
              >
                السابق
              </button>
              <span style={{ margin: '0 10px' }}>الصفحة {currentPage} من {totalPages}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{ padding: '8px 16px', border: '1px solid #ccc', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }}
              >
                التالي
              </button>
            </div>
          )}
        </>
      ) : (
        <p>لا توجد عروض متاحة.</p> // "No offers available." in Arabic
      )
}
{openModal && (
        <ComparisonModal
          onClose={handleCloseModal}
          open={openModal}
          offers={selectedOffers}
        />
      )}
      {selectedQuotation && (
        <QuotationDetailsModal
          open={!!selectedQuotation}
          onClose={handleCloseDetailsModal}
          quotation={selectedQuotation}
        />
      )}
    </div>
            </div>
        </div>
    );
}

export default ListofQuatations;