import { isEmpty } from "lodash";
import {z} from "zod";

const CompanySchema = z
  .object({
    id: z.string().nullable(),
    nameAr: z.string().min(1, "اسم الشركة مطلوب"),
    nameEn: z.string().optional(),
    website: z.string(),
    address: z.string().optional(),
    region: z.string().min(1, "المنطقة مطلوبة"),
    city: z.string().min(1, "المدينة مطلوبة"),
    registrationNumber: z.string().min(1, "رقم التسجيل مطلوب"),
    ownerType: z.enum(["Owner", "Authorized"]),
    stampedAuthorizationForm: z.string().optional().nullable(),
    expireDate: z.string(),
    creationDate: z.string().min(1, " تاريخ الإنشاء مطلوب"),
    type: z.string().optional(),
    activities: z.string().min(1, "الأنشطة مطلوبة"),
    categories: z.string(),
    logoMedia: z.string().optional().nullable(),
    contactInfo: z.string().email("عنوان البريد الإلكتروني غير صالح"),
    plaseOfIssue: z.string(),
    turnover: z.preprocess(
      (val) => (val === "" ? undefined : Number(val)),
      z
        .number({
          required_error: "حجم الأعمال مطلوب",
          invalid_type_error: "يجب إدخال رقم صحيح لحجم الأعمال",
        })
        .nonnegative("يجب أن يكون حجم الأعمال رقمًا موجبًا")
    ),
  })
  .refine(
    (data) => {
      if (data.ownerType === "Authorized") {
        return (
          data.stampedAuthorizationForm !== null &&
          data.stampedAuthorizationForm !== ""
        );
      } else {
        data.stampedAuthorizationForm = null;
        return true;
      }
    },
    {
      message: "نموذج التفويض مطلوب للمفوض",
      path: ["stampedAuthorizationForm"],
    }
  )

  .refine(
    (data) => {
      // Convert creationDate and expireDate to Date objects
      if(isEmpty(data.expireDate)){
        return true
      }
      const creationDate = new Date(data.creationDate);
      const expireDate = new Date(data.expireDate);

      // Check if expireDate is greater than creationDate
      return expireDate > creationDate;
    },
    {
      message: "تاريخ الانتهاء يجب أن يكون أكبر من تاريخ الإنشاء", // Custom error message in Arabic
      path: ["expireDate"], // Specify the path to the field that has the error
    }
  );

export default CompanySchema;
