import React from "react";
import NavPage from "../landingPage/presentation/nav/nav-page";
import Footer from "../footer";
import TrenderQuatationslistCompare from "./components/view/tender-list-compare";
// Exemple de données pour les opportunités


const QuatationTendersPage: React.FC = () => {

  return (
    <div className="flex flex-col min-h-screen" style={{ background: "#f8fafa !important" }}>
    <NavPage 
    isForm={0}
    />

    <div className="container mx-auto px-4 py-8" style={{ overflow: "auto", paddingTop: 130 }}>
      
      <div className=" mb-6 gap-4" style={{height:900}}>
      <TrenderQuatationslistCompare/>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default QuatationTendersPage;
