import NavPage from "../landingPage/presentation/nav/nav-page";
import FAQ from "./FAQ-section";
import Footer from "../footer";
function AboutUsPage() {
  return (
    <div className="flex flex-col min-h-screen bg-[#f8fafa]">
      {/* Navbar */}
      <NavPage 
      isForm={0}
      />

      {/* Main Content */}
      <div className="flex-grow flex justify-center pt-32 pb-16 px-4 sm:px-8 overflow-auto">
        <main className="flex flex-col items-center justify-center w-full max-w-6xl">
          <section className="bg-white p-8 sm:p-10 rounded-lg shadow-lg w-full">
            {/* Who We Are */}
            <div className="mb-8">
              <h1 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-2">
                من نحن
              </h1>
              <p className="text-sm sm:text-base text-yellow-600">
                نسعى لتسهيل عملية شراء المستلزمات الطبية.
              </p>
            </div>

            {/* What is Medex */}
            <div className="mb-8">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                ما هي ميدكس؟
              </h2>
              <p className="text-gray-700">
                ميدكس هي منصة ذكية تربط المستخدمين بأفضل موردي المستلزمات
                الطبية، مما يسهل الوصول إلى المعدات والخدمات الطبية المتنوعة.
                بالإضافة إلى ذلك، تقدم ميدكس حلولًا تمويلية تساعد الأفراد أو
                المؤسسات في تمويل شراء المستلزمات الطبية، مما يسهل عليهم الحصول
                على المعدات والخدمات التي يحتاجونها بمرونة مالية أكبر.
              </p>
            </div>

            {/* Medex Mission */}
            <div className="mb-8">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                رسالة ميدكس:
              </h2>
              <p className="text-gray-700">
                "نحن في ميدكس نسعى إلى تحويل تجربة شراء المستلزمات الطبية إلى
                رحلة سلسة وموفرة للوقت والجهد، من خلال توفير منصة رقمية متكاملة
                تربط بين المشترين والموردين، مع تقديم حلول تمويلية مخصصة لدعم
                نمو القطاع الصحي."
              </p>
              <p className="text-gray-700 mt-2">
                تعكس هذه الرسالة التزام ميدكس بتسهيل عملية شراء المستلزمات
                الطبية عبر تقنيات رقمية متقدمة، وتوفير خيارات تمويلية مبتكرة
                لتعزيز كفاءة ونمو القطاع الصحي.
              </p>
            </div>

          

            {/* How Medex Works */}
            <div className="mb-8">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-2">
                كيف تعمل ميدكس؟
              </h2>
              <ul className="list-disc list-inside text-gray-700">
                <li>
                <strong>طرح المناقصات:</strong> يقوم المشتري بنشر مناقصته على المنصة، مع تضمين
                  جميع التفاصيل والمتطلبات اللازمة.
                </li>
                <li>
                <strong>تقديم العروض:</strong> يتقدم الموردون بعروض أسعارهم عبر المنصة، وفقًا
                  لمواصفات المناقصة.
                </li>
                <li>
                <strong>التقييم والمقارنة:</strong> يقوم المشتري بتقييم العروض المقدمة
                  ومقارنتها لاختيار العرض الذي يتناسب مع احتياجاته.
                </li>
                <li>
                <strong>التمويل:</strong> توفر ميدكس حلول تمويلية مرنة تساعد المشترين في تغطية
                  تكاليف المستلزمات الطبية بكل سهولة ومرونة.
                </li>
              </ul>
              <p className="text-gray-700 mt-2">
                هذا النظام يضمن تجربة شراء شفافة وفعالة، مما يسهل على المشترين
                العثور على أفضل الموردين والحصول على التمويل المناسب.
              </p>
            </div>

            {/* FAQ Section */}
            <div>
              <FAQ />
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUsPage;
