import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ExtraLargeGrid from './responsive/ExtraLargeGrid';
import MobileGrid from './responsive/MobileGrid';

interface MediaQueryProps {
  children: React.ReactNode;
}

const Desktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992, maxWidth: 1199 });
  return isDesktop ? <>{children}</> : null;
};

const LargeDesktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isLargeDesktop = useMediaQuery({ minWidth: 1200, maxWidth: 1499 });
  return isLargeDesktop ? <>{children}</> : null;
};

const ExtraLargeDesktop: React.FC<MediaQueryProps> = ({ children }) => {
  const isExtraLargeDesktop = useMediaQuery({ minWidth: 1500 });
  return isExtraLargeDesktop ? <>{children}</> : null;
};

const Tablet: React.FC<MediaQueryProps> = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? <>{children}</> : null;
};

const Mobile: React.FC<MediaQueryProps> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? <>{children}</> : null;
};

const Responsive: React.FC = () => {
  return (
    <div>
      <ExtraLargeDesktop><ExtraLargeGrid /></ExtraLargeDesktop>
      <LargeDesktop><ExtraLargeGrid /></LargeDesktop>
      <Desktop><ExtraLargeGrid /></Desktop>
      <Tablet><ExtraLargeGrid /></Tablet>
      <Mobile><MobileGrid /></Mobile>
    </div>
  );
};

export default Responsive;
