import CompanyRegistrationFormView from "./components/views/company-form-view"; // Update the path as needed
import NavPage from "../landingPage/presentation/nav/nav-page";
import Footer from "../footer";
export default function CompanyPage() {
  return (
    <div className="flex flex-col ">
     <NavPage 
      isForm={0}
      />
    <div className="sm:flex xs:justify-center sm:justify-center" style={{overflow:"auto",paddingTop:80}}>
      <main className="flex min-h-screen items-center  justify-center shadow-2xl w-full flex-col">
        <section style={{margin:"130px 0px"}} className="w-full max-w-xs xs:justify-center bg-primary-white p-5 rounded-md xs:max-w-5xl py-6">
          <div className="flex flex-col" >
            <h1 className="text-2xl font-semibold sm:text-3xl">أضف شركة</h1>
            <p className="mb-4 text-secondary-brightYellow text-sm sm:text-base">
              للتقديم على أحدث الفرص
            </p>
          </div>
          <CompanyRegistrationFormView />{" "}
          {/* Use the CompanyRegistrationFormView for company registration */}
        </section>
      </main>
    </div>
    <Footer />
    </div>
  );
}
