// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Import your root reducer

const store = configureStore({
  reducer: rootReducer, // Add your root reducer here
  devTools: process.env.NODE_ENV !== 'production', // Enable DevTools in development
});

export type RootState = ReturnType<typeof store.getState>; // Define RootState type
export type AppDispatch = typeof store.dispatch; // Define AppDispatch type

export default store;
