import {useState} from "react";
import axios from "axios";
import {z} from "zod";
import ConfirmSchema from "@/app/features/auth/@core/schema/confirm-schema";
import api from "@/store/api"; 

const useConfirm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const confirm = async (values: z.infer<typeof ConfirmSchema>) => {
    setIsLoading(true);
    setError(null);

    try {
      const email = sessionStorage.getItem("email");
      const response = await api.post("/web/auth/confirm", {...values, email});
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message || "An error occurred during confirmation"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  const resendCode = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const email = sessionStorage.getItem("email");
      const response = await api.post("/api/resend-code", {email});
      setIsLoading(false);
      return response.data;
    } catch (err) {
      setIsLoading(false);
      if (axios.isAxiosError(err)) {
        setError(
          err.response?.data?.message ||
            "An error occurred while resending the code"
        );
      } else {
        setError("An unexpected error occurred");
      }
      throw err;
    }
  };

  return {confirm, resendCode, isLoading, error};
};

export default useConfirm;
